import { fetchFromChatGPT } from './fetchFromChatGPT';
import { formatDataForAPI } from './dataStructure';

/**
 * Fetch Quick Add Responsibilities
 * Fetches responsibilities suggestions from GPT based on the provided form data.
 *
 * @param {object} formData - The aggregated form data from FormContext.
 * @param {boolean} trigger - Whether to trigger the GPT call
 * @returns {Array} Array of responsibilities, or an empty array on failure.
 */
export const fetchQuickAddsResponsibilities = async (formData = {}, trigger = false) => {
  console.log('[fetchQuickAddsResponsibilities] Received formData:', formData, 'Trigger:', trigger);

  if (!trigger) {
    return [];
  }

  try {
    const inputData = formatDataForAPI(formData);
    console.log('[fetchQuickAddsResponsibilities] Formatted Input Data:', inputData);

    const gptPayload = {
      model: 'gpt-3.5-turbo',
      messages: [
        {
          role: 'system',
          content: `
            You are an assistant tasked with generating exactly 4 key responsibilities for a role.
            Use the context provided to ensure the responsibilities align with the job description and company details.
            Only return the responsibilities as a JSON array. Do not include any other text or explanation.
            If no sufficient context is provided, generate generic responsibilities that can apply to any professional role.
          `,
        },
        {
          role: 'user',
          content: JSON.stringify(inputData, null, 2),
        },
      ],
      temperature: 0.7,
      max_tokens: 500,
    };

    const response = await fetchFromChatGPT(gptPayload);
    const content = response.choices?.[0]?.message?.content || '';
    let parsed = [];

    try {
      parsed = JSON.parse(content);
    } catch (error) {
      console.warn('[fetchQuickAddsResponsibilities] Failed to parse GPT response. Returning empty array.', { content });
    }

    return Array.isArray(parsed) ? parsed.slice(0, 4) : [];
  } catch (error) {
    console.error('[fetchQuickAddsResponsibilities] Error:', error);
    return [];
  }
};
