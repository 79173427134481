export const LANGUAGES = [
  // Germanic
  { code: 'en', name: 'English' },
  { code: 'de', name: 'German' },
  { code: 'nl', name: 'Dutch' },
  { code: 'da', name: 'Danish' },
  { code: 'sv', name: 'Swedish' },
  { code: 'no', name: 'Norwegian' },
  
  // Romance
  { code: 'es', name: 'Spanish' },
  { code: 'fr', name: 'French' },
  { code: 'it', name: 'Italian' },
  { code: 'pt', name: 'Portuguese' },
  { code: 'ro', name: 'Romanian' },
  { code: 'ca', name: 'Catalan' },
  
  // Slavic
  { code: 'pl', name: 'Polish' },
  { code: 'ru', name: 'Russian' },
  { code: 'uk', name: 'Ukrainian' },
  { code: 'cs', name: 'Czech' },
  { code: 'sk', name: 'Slovak' },
  { code: 'hr', name: 'Croatian' },
  { code: 'sr', name: 'Serbian' },
  { code: 'bg', name: 'Bulgarian' },
  
  // East Asian
  { code: 'zh', name: 'Chinese (Mandarin)' },
  { code: 'ja', name: 'Japanese' },
  { code: 'ko', name: 'Korean' },
  { code: 'vi', name: 'Vietnamese' },
  { code: 'th', name: 'Thai' },
  { code: 'id', name: 'Indonesian' },
  { code: 'ms', name: 'Malay' },
  
  // South Asian
  { code: 'hi', name: 'Hindi' },
  { code: 'bn', name: 'Bengali' },
  { code: 'ur', name: 'Urdu' },
  { code: 'ta', name: 'Tamil' },
  { code: 'te', name: 'Telugu' },
  
  // Semitic
  { code: 'ar', name: 'Arabic' },
  { code: 'he', name: 'Hebrew' },
  
  // Other Indo-European
  { code: 'el', name: 'Greek' },
  { code: 'fa', name: 'Persian' },
  { code: 'tr', name: 'Turkish' },
  { code: 'hu', name: 'Hungarian' },
  { code: 'fi', name: 'Finnish' },
  
  // Others
  { code: 'sw', name: 'Swahili' },
  { code: 'am', name: 'Amharic' },
].sort((a, b) => a.name.localeCompare(b.name));

export const POPULAR_CURRENCIES = ['EUR', 'USD', 'GBP', 'CHF', 'JPY'];

export const CURRENCIES = [
  // Europe
  { code: 'EUR', symbol: '€', name: 'Euro' },
  { code: 'GBP', symbol: '£', name: 'British Pound' },
  { code: 'CHF', symbol: 'CHF', name: 'Swiss Franc' },
  { code: 'SEK', symbol: 'kr', name: 'Swedish Krona' },
  { code: 'NOK', symbol: 'kr', name: 'Norwegian Krone' },
  { code: 'DKK', symbol: 'kr', name: 'Danish Krone' },
  { code: 'PLN', symbol: 'zł', name: 'Polish Złoty' },
  
  // Americas
  { code: 'USD', symbol: '$', name: 'US Dollar' },
  { code: 'CAD', symbol: 'C$', name: 'Canadian Dollar' },
  { code: 'BRL', symbol: 'R$', name: 'Brazilian Real' },
  { code: 'MXN', symbol: 'MX$', name: 'Mexican Peso' },
  { code: 'ARS', symbol: '$', name: 'Argentine Peso' },
  
  // Asia Pacific
  { code: 'JPY', symbol: '¥', name: 'Japanese Yen' },
  { code: 'CNY', symbol: '¥', name: 'Chinese Yuan' },
  { code: 'HKD', symbol: 'HK$', name: 'Hong Kong Dollar' },
  { code: 'SGD', symbol: 'S$', name: 'Singapore Dollar' },
  { code: 'KRW', symbol: '₩', name: 'South Korean Won' },
  { code: 'INR', symbol: '₹', name: 'Indian Rupee' },
  { code: 'AUD', symbol: 'A$', name: 'Australian Dollar' },
  { code: 'NZD', symbol: 'NZ$', name: 'New Zealand Dollar' },
  
  // Middle East & Africa
  { code: 'AED', symbol: 'د.إ', name: 'UAE Dirham' },
  { code: 'SAR', symbol: '﷼', name: 'Saudi Riyal' },
  { code: 'ZAR', symbol: 'R', name: 'South African Rand' },
].sort((a, b) => a.name.localeCompare(b.name)); 

// Add language to currency mapping
export const LANGUAGE_TO_CURRENCY = {
  // Germanic Languages
  de: 'EUR',  // German
  nl: 'EUR',  // Dutch
  da: 'DKK',  // Danish
  sv: 'SEK',  // Swedish
  no: 'NOK',  // Norwegian
  en: 'GBP',  // English (UK default)
  
  // Romance Languages
  es: 'EUR',  // Spanish
  fr: 'EUR',  // French
  it: 'EUR',  // Italian
  pt: 'EUR',  // Portuguese (Portugal)
  ro: 'RON',  // Romanian
  ca: 'EUR',  // Catalan
  
  // Slavic Languages
  pl: 'PLN',  // Polish
  ru: 'RUB',  // Russian
  uk: 'UAH',  // Ukrainian
  cs: 'CZK',  // Czech
  sk: 'EUR',  // Slovak
  hr: 'EUR',  // Croatian
  sr: 'RSD',  // Serbian
  bg: 'BGN',  // Bulgarian
  
  // East Asian
  zh: 'CNY',  // Chinese
  ja: 'JPY',  // Japanese
  ko: 'KRW',  // Korean
  vi: 'VND',  // Vietnamese
  th: 'THB',  // Thai
  id: 'IDR',  // Indonesian
  ms: 'MYR',  // Malay
  
  // South Asian
  hi: 'INR',  // Hindi
  bn: 'BDT',  // Bengali
  ur: 'PKR',  // Urdu
  ta: 'INR',  // Tamil (India default)
  te: 'INR',  // Telugu
  
  // Semitic
  ar: 'AED',  // Arabic (UAE default)
  he: 'ILS',  // Hebrew
  
  // Other Indo-European
  el: 'EUR',  // Greek
  fa: 'IRR',  // Persian
  tr: 'TRY',  // Turkish
  hu: 'HUF',  // Hungarian
  fi: 'EUR',  // Finnish
  
  // Others
  sw: 'KES',  // Swahili (Kenya default)
  am: 'ETB',  // Amharic
};

export const getCurrencyForLanguage = (languageCode) => {
  return LANGUAGE_TO_CURRENCY[languageCode] || 'EUR';
}; 