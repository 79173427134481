// Initialize data layer
window.dataLayer = window.dataLayer || [];

// Utility function to push events to data layer
export const pushToDataLayer = (event) => {
  window.dataLayer.push(event);
};

// Application-specific GTM events
export const GTMEvents = {
  // Basic page tracking
  PAGE_VIEW: (pageTitle, pagePath) => ({
    event: 'page_view',
    page: {
      title: pageTitle,
      path: pagePath,
    }
  }),

  // Core feature usage
  GENERATE_CONTENT: (details) => ({
    event: 'generate_content',
    content_type: details.type, // 'questions', 'timeline', 'responsibilities', 'outcomes'
    success: details.success,
    error: details.error || null
  }),

  // Export actions
  EXPORT: (details) => ({
    event: 'export',
    format: details.format, // 'pdf', 'docx', 'json'
    content_type: details.type, // 'questions', 'role'
    success: details.success,
    error: details.error || null
  }),

  // Pro mode interactions
  PRO_MODE: (details) => ({
    event: 'pro_mode',
    action: details.action, // 'enable', 'disable'
    source: details.source // where the action was triggered from
  }),

  // Form completion tracking
  FORM_COMPLETE: (formName) => ({
    event: 'form_complete',
    form_name: formName // 'company', 'role', 'responsibilities', etc.
  })
};

/* Example usage:

// Track AI generation
pushToDataLayer(GTMEvents.GENERATE_CONTENT({
  type: 'questions',
  success: true
}));

// Track exports
pushToDataLayer(GTMEvents.EXPORT({
  format: 'pdf',
  type: 'questions',
  success: true
}));

// Track pro mode
pushToDataLayer(GTMEvents.PRO_MODE({
  action: 'enable',
  source: 'navbar'
}));

// Track form completion
pushToDataLayer(GTMEvents.FORM_COMPLETE('company_profile'));
*/