import { factors, FACTOR_CATEGORIES } from '../interviewFactorsList';

export const QUESTION_TYPES = {
  technical: {
    label: 'Technical Competence',
    category: 'technical',
    bg: 'bg-[#fff5e6]',
    text: 'text-[#faa00c]',
    hover: 'hover:bg-[#fff0d6]',
    description: 'Focus on technical abilities and qualifications',
    factors: ['professional_experience', 'skills', 'education']
  },
  performance: {
    label: 'Problem-Solving and Performance',
    category: 'performance',
    bg: 'bg-[#f3e6f5]',
    text: 'text-[#87328f]',
    hover: 'hover:bg-[#edd6ef]',
    description: 'Evaluate problem-solving and achievements',
    factors: ['achievements', 'progression', 'case_factor']
  },
  cultural: {
    label: 'Cultural and Interpersonal Fit',
    category: 'cultural',
    bg: 'bg-[#f2fae6]',
    text: 'text-[#84bd00]',
    hover: 'hover:bg-[#ecf7d6]',
    description: 'Assess cultural alignment and soft skills',
    factors: ['cultural_fit', 'attitude']
  }
};

export const FACTOR_TO_CATEGORY = Object.entries(QUESTION_TYPES).reduce((map, [category, data]) => {
  data.factors.forEach(factorId => {
    map[factorId] = category;
  });
  return map;
}, {});

export const getTypeStyles = (type) => {
  const category = FACTOR_TO_CATEGORY[type] || type;
  return QUESTION_TYPES[category]?.bg + ' ' + QUESTION_TYPES[category]?.text;
};

export const getTypeLabel = (type) => {
  const factor = factors.find(f => f.id === type);
  if (factor) return factor.label;
  
  return QUESTION_TYPES[type]?.label || type;
};

export const getRandomFactorFromCategory = (category) => {
  const factorIds = QUESTION_TYPES[category]?.factors || [];
  return factorIds[Math.floor(Math.random() * factorIds.length)];
};

export const getCategoryFactors = (category) => {
  return QUESTION_TYPES[category]?.factors || [];
}; 