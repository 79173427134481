import React from 'react';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

const SwaggerDocs = () => {
  return (
    <div style={{ padding: '20px' }}>
      <SwaggerUI url="/api-docs.yaml" />
    </div>
  );
};

export default SwaggerDocs;
