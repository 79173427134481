import React, { useState } from 'react';
import { fetchCompanyProfile } from '../utils/fetchCompanyProfile';
import { Building, Globe, Sparkles, ChevronDown, ChevronUp } from 'lucide-react';
import { 
  Card, 
  CardHeader, 
  CardTitle, 
  CardContent,
  GradientButton,
  CardSubHeader,
  hasAllRequiredFields
} from '../components/CardComponents';
import { useFormContext } from '../contexts/FormContext';
import { showToast, TOAST_TYPES } from './common/ToastManager';

const CompanyProfile = () => {
  const { formData, updateField } = useFormContext();
  const companyData = formData.companyData || {};
  const [url, setUrl] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const requiredFields = ['name', 'industry', 'description', 'culture'];

  const handleFetchCompanyInfo = async () => {
    setIsLoading(true);
    try {
      showToast('Fetching company data...', TOAST_TYPES.INFO);
      
      const data = await fetchCompanyProfile(url);
      if (data) {
        showToast('Successfully fetched company data', TOAST_TYPES.SUCCESS);
        
        Object.entries(data).forEach(([key, value], index) => {
          setTimeout(() => {
            updateField('companyData', key, value || '');
            showToast(`Updated ${key.replace(/([A-Z])/g, ' $1').toLowerCase()}`, TOAST_TYPES.INFO);
          }, index * 100);
        });

        setTimeout(() => {
          showToast('All company information updated! ✨', TOAST_TYPES.SUCCESS);
          setIsExpanded(true);
        }, Object.keys(data).length * 100 + 100);

      } else {
        showToast('No company information found', TOAST_TYPES.WARNING);
      }
    } catch (error) {
      console.error('Error fetching company profile:', error);
      showToast('Failed to fetch company information', TOAST_TYPES.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (field, value) => {
    updateField('companyData', field, value);
  };

  return (
    <Card className="max-w-5xl mx-auto">
      <CardHeader 
        data={companyData}
        requiredFields={requiredFields}
        isExpanded={isExpanded}
        onClick={() => setIsExpanded(!isExpanded)}
        inputSection={
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-2 relative w-[250px]">
              <Globe className="absolute left-3 h-5 w-5 text-gray-400" />
              <input
                type="url"
                value={url}
                onChange={(e) => setUrl(e.target.value)}
                className="w-full pl-10 pr-3 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                placeholder="https://company.com"
                onClick={(e) => e.stopPropagation()}
              />
            </div>
            <GradientButton
              onClick={(e) => {
                e.stopPropagation();
                handleFetchCompanyInfo();
              }}
              disabled={isLoading || !url}
              icon={Sparkles}
              loadingText="Analyzing"
              isLoading={isLoading}
              className="!bg-gradient-to-r !from-blue-600 !to-blue-500 hover:!from-blue-700 hover:!to-blue-600 !px-3"
            >
              AI Fetch
            </GradientButton>
          </div>
        }
      >
        <div className="flex items-center gap-2">
          <Building className="h-5 w-5" />
          <div className="min-w-0 flex-1">
            <CardTitle>{companyData.name || 'Company Profile'}</CardTitle>
            <CardSubHeader>
              {hasAllRequiredFields(companyData, requiredFields) ? (
                <span className="flex items-center min-w-0">
                  <span className="text-green-600 truncate">
                    {companyData.industry} • {companyData.description?.substring(0, 50)}...
                  </span>
                </span>
              ) : (
                'Add company details'
              )}
            </CardSubHeader>
          </div>
        </div>
      </CardHeader>

      {isExpanded && (
        <CardContent className="space-y-6">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Company Name
              </label>
              <input
                type="text"
                value={companyData.name || ''}
                onChange={(e) => handleInputChange('name', e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Industry
              </label>
              <input
                type="text"
                value={companyData.industry || ''}
                onChange={(e) => handleInputChange('industry', e.target.value)}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div className="col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Description
              </label>
              <textarea
                value={companyData.description || ''}
                onChange={(e) => handleInputChange('description', e.target.value)}
                rows={3}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>

            <div className="col-span-2">
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Culture & Values
              </label>
              <textarea
                value={companyData.culture || ''}
                onChange={(e) => handleInputChange('culture', e.target.value)}
                rows={3}
                className="w-full p-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
              />
            </div>
          </div>
        </CardContent>
      )}
    </Card>
  );
};

export default CompanyProfile;
