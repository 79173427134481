import React from 'react';
import { APP_BASENAME } from '../App';
import ModeToggle from './ModeToggle';
import { useFormContext } from '../contexts/FormContext';

const Header = () => {
  const { isProMode, toggleProMode } = useFormContext();

  return (
    <header className="max-w-5xl mx-auto mb-8">
      <div className="flex justify-between items-center">
        <a href={APP_BASENAME} className="flex items-center">
          <img
            src={APP_BASENAME + "/predicthire.png"}
            alt="PredictHire"
            className="h-8 w-auto"
          />
        </a>
        <div className="flex items-center gap-4">
          <ModeToggle
            isProMode={isProMode}
            setIsProMode={toggleProMode}
          />
          <div className="flex items-center">
            <a
              href="https://app.predicthire.com/signin"
              className="py-3 text-base font-medium px-7 text-gray-800 hover:text-blue-600 transition-colors"
            >
              Login
            </a>
            <a
              href="https://app.predicthire.com/register"
              className="py-3 text-base font-medium text-white rounded-md bg-blue-600 px-7 hover:bg-blue-700 transition-colors"
            >
              Sign Up
            </a>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header; 