import React, { useState, useEffect } from 'react';
import { Calendar, ChevronDown, ChevronUp, Sparkles, Trash2, PlusCircle, Info } from 'lucide-react';
import { 
  Card, 
  CardHeader, 
  CardTitle, 
  CardContent, 
  CardSubHeader,
  GradientButton,
  hasAllRequiredFields
} from './CardComponents';
import { useFormContext } from '../contexts/FormContext';
import { showToast, TOAST_TYPES } from './common/ToastManager';
import { fetchQuickAddTimeline } from '../utils/fetchQuickAddTimeline';
import { formatDate, calculateDaysFromNow, createInitialTimeline, calculateAdjustedDates } from '../utils/timeline';
import * as Tooltip from '@radix-ui/react-tooltip';
import QuickAddPanel from './common/QuickAddPanel';

// Loader GIF URL
const loaderGifUrl = '/loader.gif';

const TimelineProcess = () => {
  const { formData, updateTimelines } = useFormContext();
  const [timelineSteps, setTimelineSteps] = useState(formData.timelines?.steps || []);
  const [quickAddSteps, setQuickAddSteps] = useState([]);
  const [loadingSuggestions, setLoadingSuggestions] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [previousTargetDate, setPreviousTargetDate] = useState(null);
  const [needsDateAdjustment, setNeedsDateAdjustment] = useState(false);
  const [isQuickAddCollapsed, setIsQuickAddCollapsed] = useState(false);

  // Consolidated date handling function
  const updateTargetDate = (newDate) => {
    const oldDate = formData.timelines?.targetStartDate;
    
    // Update both target date and steps in one update
    updateTimelines({
      targetStartDate: newDate,
      steps: timelineSteps.map(step => 
        step.text === 'Target Candidate Start Date' 
          ? { ...step, date: newDate }
          : step
      ).sort((a, b) => new Date(a.date) - new Date(b.date))
    });

    // Store previous date for potential adjustments
    setPreviousTargetDate(oldDate);
    setIsExpanded(true);

    // Check if we need date adjustment
    const hasIntermediateSteps = timelineSteps.some(step => 
      step.text !== 'Initial Role Definition' && 
      step.text !== 'Target Candidate Start Date'
    );

    if (hasIntermediateSteps && oldDate && newDate && oldDate !== newDate) {
      setNeedsDateAdjustment(true);
    }
  };

  // Handler for header date change
  const handleTargetDateChange = (date) => {
    updateTargetDate(date);
  };

  // Handler for list date changes
  const handleDateChange = (index, newDate) => {
    const step = timelineSteps[index];
    
    if (step.text === 'Target Candidate Start Date') {
      updateTargetDate(newDate);
    } else {
      const newSteps = [...timelineSteps];
      newSteps[index].date = newDate;
      const sortedSteps = newSteps.sort((a, b) => new Date(a.date) - new Date(b.date));
      setTimelineSteps(sortedSteps);
    }
  };

  // Handler for adjusting all dates
  const handleAdjustAllDates = () => {
    const newDate = formData.timelines?.targetStartDate;
    const oldDate = previousTargetDate;
    
    if (oldDate && newDate && oldDate !== newDate) {
      // Calculate adjusted dates
      const adjustedSteps = calculateAdjustedDates(timelineSteps, oldDate, newDate);
      
      // Update timeline steps with adjusted dates
      setTimelineSteps(adjustedSteps);
      saveTimelineSteps(adjustedSteps);
      
      // Update previous target date and reset adjustment flag
      setPreviousTargetDate(newDate);
      setNeedsDateAdjustment(false);
      
      showToast('Timeline dates adjusted successfully', TOAST_TYPES.SUCCESS);
    }
  };

  // Initialize timeline when target date is set
  useEffect(() => {
    const targetDate = formData.timelines?.targetStartDate;
    if (targetDate && timelineSteps.length === 0) {
      const today = new Date().toISOString().split('T')[0];
      const initialSteps = createInitialTimeline(today, targetDate);
      setTimelineSteps(initialSteps);
      setPreviousTargetDate(targetDate);
      saveTimelineSteps(initialSteps);
    }
  }, [formData.timelines?.targetStartDate]);

  const handleGenerateSuggestions = async () => {
    if (!formData.timelines?.targetStartDate) {
      showToast('Please set a target start date first', TOAST_TYPES.WARNING);
      return;
    }

    setLoadingSuggestions(true);
    setIsExpanded(true);

    try {
      const suggestions = await fetchQuickAddTimeline({
        targetDate: formData.timelines.targetStartDate,
        existingSteps: timelineSteps
      });

      const formattedSuggestions = suggestions.map((s) => ({
        label: s.Suggestions,
        date: s.timeframe,
        duration: s.duration || ''
      }));

      setQuickAddSteps(formattedSuggestions);
      showToast('Timeline suggestions generated successfully', TOAST_TYPES.SUCCESS);
    } catch (error) {
      console.error('Error generating suggestions:', error);
      showToast('Failed to generate timeline suggestions', TOAST_TYPES.ERROR);
    } finally {
      setLoadingSuggestions(false);
    }
  };

  const handleAddQuickStep = (step) => {
    const newStep = {
      id: Date.now(),
      text: step.label,
      date: step.date,
      duration: step.duration
    };

    setTimelineSteps(prev => {
      const newSteps = [...prev];
      const insertIndex = newSteps.findIndex(s => new Date(s.date) > new Date(step.date));
      if (insertIndex === -1) {
        newSteps.push(newStep);
      } else {
        newSteps.splice(insertIndex, 0, newStep);
      }
      const sortedSteps = newSteps.sort((a, b) => new Date(a.date) - new Date(b.date));
      saveTimelineSteps(sortedSteps);
      return sortedSteps;
    });

    setQuickAddSteps(prev => prev.filter(s => s.label !== step.label));
  };

  const handleDeleteStep = (id) => {
    setTimelineSteps(prev => {
      const newSteps = prev.filter(step => step.id !== id);
      saveTimelineSteps(newSteps);
      return newSteps;
    });
  };

  const handleAddStep = () => {
    const newStep = {
      id: Date.now(),
      text: '',
      date: '',
      duration: ''
    };
    
    setTimelineSteps(prev => {
      const newSteps = [...prev, newStep];
      saveTimelineSteps(newSteps);
      return newSteps;
    });
  };

  // Update how we save timeline data - focusing only on timeline structure
  const saveTimelineData = (steps) => {
    updateTimelines({
      steps,
      targetStartDate: formData.timelines?.targetStartDate || ''
    });
  };

  // Add this function to save timeline steps
  const saveTimelineSteps = (steps) => {
    setTimelineSteps(steps);
  };

  // Update context only when steps actually change
  useEffect(() => {
    const currentSteps = formData.timelines?.steps || [];
    const stepsChanged = JSON.stringify(currentSteps) !== JSON.stringify(timelineSteps);
    
    if (stepsChanged) {
      updateTimelines({
        steps: timelineSteps,
        targetStartDate: formData.timelines?.targetStartDate || ''
      });
    }
  }, [timelineSteps]); // Only depend on timelineSteps

  // Sync local state when formData changes externally
  useEffect(() => {
    const externalSteps = formData.timelines?.steps || [];
    const localSteps = timelineSteps;
    
    if (JSON.stringify(externalSteps) !== JSON.stringify(localSteps)) {
      setTimelineSteps(externalSteps);
    }
  }, [formData.timelines?.steps]);

  return (
    <div className="max-w-5xl mx-auto space-y-4">
      <Card className="max-w-5xl mx-auto">
        <CardHeader 
          data={formData.timelines}
          requiredFields={['steps']}
          isExpanded={isExpanded}
          onClick={() => setIsExpanded(!isExpanded)}
          inputSection={
            <div className="flex items-center gap-2">
              <div className="flex items-center gap-2 relative w-[250px]">
                <input
                  type="date"
                  value={formData.timelines?.targetStartDate || ''}
                  onChange={(e) => handleTargetDateChange(e.target.value)}
                  className="w-full p-2 border rounded-md"
                  onClick={(e) => e.stopPropagation()}
                />
              </div>
              <GradientButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleGenerateSuggestions();
                }}
                disabled={loadingSuggestions}
                icon={Sparkles}
                loadingText="Generating"
                isLoading={loadingSuggestions}
                className="!bg-gradient-to-r !from-blue-600 !to-blue-500 hover:!from-blue-700 hover:!to-blue-600 !px-3"
              >
                Generate
              </GradientButton>
            </div>
          }
        >
          <div className="flex items-center gap-2">
            <Calendar className="h-5 w-5" />
            <div>
              <CardTitle>Timeline & Process</CardTitle>
              <CardSubHeader>
                {formData.timelines?.steps?.length > 0 ? (
                  <span>
                    Target Start: {formatDate(formData.timelines?.targetStartDate)}
                  </span>
                ) : (
                  'Set target start date'
                )}
              </CardSubHeader>
            </div>
          </div>
        </CardHeader>

        {isExpanded && (
          <CardContent className="space-y-6">
            <div className={`grid ${isQuickAddCollapsed ? 'grid-cols-1' : 'grid-cols-3'} gap-6`}>
              {/* Left Column: Timeline Steps */}
              <div className={isQuickAddCollapsed ? 'col-span-1' : 'col-span-2'}>
                {needsDateAdjustment && (
                  <div className="mb-4 p-3 bg-yellow-50 border border-yellow-200 rounded-lg flex items-center justify-between">
                    <span className="text-sm text-yellow-800">
                      Target date has changed. Would you like to adjust all timeline dates accordingly?
                    </span>
                    <button
                      onClick={handleAdjustAllDates}
                      className="px-3 py-1 bg-yellow-100 text-yellow-800 rounded-md text-sm hover:bg-yellow-200 transition-colors"
                    >
                      Adjust Dates
                    </button>
                  </div>
                )}
                <div className="space-y-3">
                  <h3 className="text-sm font-medium text-gray-700 mb-2">Timeline Steps</h3>
                  <div className="space-y-3">
                    {timelineSteps.map((step, index) => (
                      <div key={step.id} className="flex flex-col gap-1 p-3 border rounded-lg bg-white">
                        <div className="flex items-start gap-4">
                          <div className="flex-grow min-w-0">
                            <input
                              type="text"
                              value={step.text}
                              onChange={(e) => {
                                const newSteps = [...timelineSteps];
                                newSteps[index].text = e.target.value;
                                setTimelineSteps(newSteps);
                              }}
                              className="w-full p-2 border border-gray-300 rounded-md text-xs"
                              placeholder="What needs to be done?"
                            />
                          </div>
                          <div className="flex items-center gap-2 flex-shrink-0">
                            <input
                              type="date"
                              value={step.date || ''}
                              onChange={(e) => handleDateChange(index, e.target.value)}
                              className="w-32 p-2 border rounded-md text-sm"
                            />
                            <button
                              onClick={() => handleDeleteStep(step.id)}
                              className="p-1 rounded hover:bg-red-100"
                            >
                              <Trash2 className="h-4 w-4 text-red-600" />
                            </button>
                          </div>
                        </div>
                        {step.date && (
                          <p className="text-xs text-gray-500">
                            {calculateDaysFromNow(step.date)}
                            {step.duration && ` • ${step.duration}`}
                          </p>
                        )}
                      </div>
                    ))}
                  </div>
                  <button
                    onClick={handleAddStep}
                    className="flex items-center gap-1.5 text-blue-600 hover:text-blue-800 mt-4 text-sm"
                  >
                    <PlusCircle className="h-4 w-4" />
                    Add Timeline Step
                  </button>
                </div>
              </div>

              {/* Replace the existing Quick Add section with QuickAddPanel */}
              <QuickAddPanel
                isCollapsed={isQuickAddCollapsed}
                onCollapse={setIsQuickAddCollapsed}
                isLoading={loadingSuggestions}
                items={quickAddSteps}
                onItemClick={handleAddQuickStep}
                onReload={handleGenerateSuggestions}
                renderItem={(step, index) => (
                  <button
                    key={index}
                    onClick={() => handleAddQuickStep(step)}
                    className="w-full text-left flex flex-col gap-1 p-2 rounded border border-gray-200 bg-white hover:border-blue-500 hover:bg-blue-50 transition-colors"
                  >
                    <span className="font-medium text-sm">{step.label}</span>
                    <span className="text-xs text-gray-500">
                      {formatDate(step.date)} ({calculateDaysFromNow(step.date)})
                    </span>
                    {step.duration && (
                      <span className="text-xs text-gray-400">Duration: {step.duration}</span>
                    )}
                  </button>
                )}
              />
            </div>
          </CardContent>
        )}
      </Card>
    </div>
  );
};

export default TimelineProcess;
