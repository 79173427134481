// File: react-app/src/components/RoleOverview.jsx
import React, { useState, useEffect } from 'react';
import { Briefcase, Sparkles, Building, Plus, X } from 'lucide-react';
import { APP_BASENAME } from '../App';
import { 
  Card, 
  CardHeader, 
  CardTitle, 
  CardContent, 
  CardSubHeader,
  GradientButton,
  hasAllRequiredFields 
} from './CardComponents';
import { useFormContext } from '../contexts/FormContext';
import { fetchRoleContent } from '../utils/fetchRoleContent';
import { showToast, TOAST_TYPES } from './common/ToastManager';
import { LANGUAGES, CURRENCIES, POPULAR_CURRENCIES, getCurrencyForLanguage } from '../utils/constants';

const RoleOverview = () => {
  const { formData, updateField } = useFormContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const roleData = formData?.roleData || {};

  const requiredFields = ['jobTitle', 'department', 'roleContext'];

  const [languageSearch, setLanguageSearch] = useState('');
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false);

  const toggleField = (fieldName) => {
    updateField('roleData', fieldName, roleData[fieldName] === null ? '' : null);
  };

  const handleFetchRoleInfo = async () => {
    setIsLoading(true);
    try {
      showToast('Analyzing role context...', TOAST_TYPES.INFO);
      
      const data = await fetchRoleContent(roleData.roleContext);
      if (data) {
        showToast('Successfully analyzed role context', TOAST_TYPES.SUCCESS);
        
        Object.entries(data).forEach(([key, value], index) => {
          setTimeout(() => {
            updateField('roleData', key, value || '');
            showToast(`Updated ${key.replace(/([A-Z])/g, ' $1').toLowerCase()}`, TOAST_TYPES.INFO);
          }, index * 100);
        });

        setTimeout(() => {
          showToast('All role information updated! ✨', TOAST_TYPES.SUCCESS);
          setIsExpanded(true);
        }, Object.keys(data).length * 100 + 100);
      }
    } catch (error) {
      console.error('Error fetching role info:', error);
      showToast('Failed to analyze role context', TOAST_TYPES.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const formatSalaryInput = (value, currency) => {
    if (!value) return value;
    
    // Remove any existing formatting (dots and commas)
    const numberOnly = value.replace(/[^0-9-]/g, '');
    
    // Define currencies that use comma as decimal separator and dot as thousand separator
    const useEuropeanFormat = ['EUR', 'DKK', 'NOK', 'SEK', 'CHF'].includes(currency);
    
    // Format the number with appropriate thousand separator
    if (useEuropeanFormat) {
      // Use dots for thousands (European format)
      return numberOnly.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
    } else {
      // Use commas for thousands (US/UK format)
      return numberOnly.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
  };

  // Filter languages based on search
  const filteredLanguages = LANGUAGES.filter(lang => 
    lang.name.toLowerCase().includes(languageSearch.toLowerCase()) ||
    lang.code.toLowerCase().includes(languageSearch.toLowerCase())
  );

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (isLanguageDropdownOpen && !event.target.closest('.language-dropdown')) {
        setIsLanguageDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isLanguageDropdownOpen]);

  return (
    <Card className="max-w-5xl mx-auto">
      <CardHeader 
        data={roleData}
        requiredFields={requiredFields}
        isExpanded={isExpanded}
        onClick={() => setIsExpanded(!isExpanded)}
        inputSection={
          <div className="flex items-center gap-2">
            <div className="flex items-center gap-2 relative w-[250px]">
              <textarea
                value={roleData.roleContext || ''}
                onChange={(e) => updateField('roleData', 'roleContext', e.target.value)}
                onClick={(e) => e.stopPropagation()}
                className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-200 text-sm"
                rows={2}
                placeholder="Briefly explain why this role is open and its main purpose"
              />
            </div>
            <GradientButton
              onClick={(e) => {
                e.stopPropagation();
                handleFetchRoleInfo();
              }}
              disabled={isLoading}
              icon={Sparkles}
              loadingText="Analyzing"
              isLoading={isLoading}
              className="!bg-gradient-to-r !from-blue-600 !to-blue-500 hover:!from-blue-700 hover:!to-blue-600 !px-3"
            >
              AI Fetch
            </GradientButton>
          </div>
        }
      >
        <div className="flex items-center gap-2">
          <Briefcase className="h-5 w-5" />
          <div>
            <CardTitle>{roleData.jobTitle || 'Role Overview'}</CardTitle>
            <CardSubHeader>
              {hasAllRequiredFields(roleData, requiredFields) ? (
                <span className="flex items-center min-w-0">
                  <span className="text-green-600 truncate">
                    {roleData.department} • {roleData.roleContext?.substring(0, 50)}...
                  </span>
                </span>
              ) : (
                'Define role details'
              )}
            </CardSubHeader>
          </div>
        </div>
      </CardHeader>

      {isExpanded && (
        <CardContent>
          <div className="space-y-4">
            <div className="grid grid-cols-2 gap-4">
              {/* Job Title */}
              <div>
                <label className="block text-sm font-medium mb-1 text-gray-700">Job Title</label>
                <input
                  type="text"
                  value={roleData.jobTitle || ''}
                  onChange={(e) => updateField('roleData', 'jobTitle', e.target.value)}
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
                  placeholder="e.g., Senior Product Manager"
                />
              </div>

              {/* Department */}
              <div>
                <label className="block text-sm font-medium mb-1 text-gray-700">Department</label>
                <input
                  type="text"
                  value={roleData.department || ''}
                  onChange={(e) => updateField('roleData', 'department', e.target.value)}
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
                  placeholder="e.g., Product, Engineering, Marketing"
                />
              </div>

              {/* Reports To */}
              <div>
                <label className="block text-sm font-medium mb-1 text-gray-700">Reports To</label>
                <input
                  type="text"
                  value={roleData.reportsTo || ''}
                  onChange={(e) => updateField('roleData', 'reportsTo', e.target.value)}
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
                  placeholder="Position of direct manager"
                />
              </div>

              {/* Team Size */}
              <div>
                <label className="block text-sm font-medium mb-1 text-gray-700">Team Size</label>
                <input
                  type="text"
                  value={roleData.teamSize || ''}
                  onChange={(e) => updateField('roleData', 'teamSize', e.target.value)}
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
                  placeholder="Current team size"
                />
              </div>

              {/* Work Model */}
              <div>
                <label className="block text-sm font-medium mb-1 text-gray-700">Work Model</label>
                <select
                  value={roleData.workModel || ''}
                  onChange={(e) => updateField('roleData', 'workModel', e.target.value)}
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
                >
                  <option value="">Select work model</option>
                  <option value="Remote">Remote</option>
                  <option value="Hybrid">Hybrid</option>
                  <option value="On-site">On-site</option>
                </select>
              </div>

              {/* Employment Type */}
              <div>
                <label className="block text-sm font-medium mb-1 text-gray-700">Employment Type</label>
                <select
                  value={roleData.employmentType || ''}
                  onChange={(e) => updateField('roleData', 'employmentType', e.target.value)}
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
                >
                  <option value="">Select employment type</option>
                  <option value="Full-time">Full-time</option>
                  <option value="Part-time">Part-time</option>
                  <option value="Contract">Contract</option>
                  <option value="Temporary">Temporary</option>
                </select>
              </div>

              {/* Role Context */}
              <div className="col-span-2">
                <label className="block text-sm font-medium mb-1 text-gray-700">Role Context</label>
                <textarea
                  value={roleData.roleContext || ''}
                  onChange={(e) => updateField('roleData', 'roleContext', e.target.value)}
                  className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
                  rows={3}
                  placeholder="Why is this role open? E.g., new headcount, backfill, expansion."
                />
              </div>

              {/* Language with search */}
              <div>
                <label className="block text-sm font-medium mb-1 text-gray-700">Language</label>
                <div className="relative language-dropdown">
                  <input
                    type="text"
                    value={languageSearch}
                    onChange={(e) => setLanguageSearch(e.target.value)}
                    onFocus={() => setIsLanguageDropdownOpen(true)}
                    placeholder="Search language..."
                    className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
                  />
                  {isLanguageDropdownOpen && (
                    <div className="absolute z-10 w-full mt-1 bg-white border rounded-md shadow-lg max-h-60 overflow-auto">
                      {filteredLanguages.map(lang => (
                        <button
                          key={lang.code}
                          onClick={(e) => {
                            e.stopPropagation();
                            updateField('roleData', 'language', lang.code);
                            updateField('roleData', 'currency', getCurrencyForLanguage(lang.code));
                            setLanguageSearch(lang.name);
                            setIsLanguageDropdownOpen(false);
                          }}
                          className="w-full px-4 py-2 text-left text-sm hover:bg-gray-100 focus:bg-gray-100 focus:outline-none"
                        >
                          <span className="font-medium">{lang.name}</span>
                          <span className="text-gray-500 ml-2">({lang.code})</span>
                        </button>
                      ))}
                      {filteredLanguages.length === 0 && (
                        <div className="px-4 py-2 text-sm text-gray-500">
                          No languages found
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>

              {/* Salary with currency */}
              <div>
                <label className="block text-sm font-medium mb-1 text-gray-700">Salary</label>
                <div className="flex gap-2">
                  <div className="relative flex-1">
                    <input
                      type="text"
                      value={roleData.salary || ''}
                      onChange={(e) => {
                        const formatted = formatSalaryInput(e.target.value, roleData.currency || 'EUR');
                        updateField('roleData', 'salary', formatted);
                      }}
                      className="w-full p-2 pr-20 border rounded-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500 text-sm"
                      placeholder="e.g. 80.000 - 100.000"
                    />
                    <select
                      value={roleData.currency || 'EUR'}
                      onChange={(e) => updateField('roleData', 'currency', e.target.value)}
                      className="absolute right-0 top-0 h-full px-2 border-l text-sm bg-gray-50 rounded-r-md focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                    >
                      {/* Popular currencies first */}
                      {POPULAR_CURRENCIES.map(code => {
                        const curr = CURRENCIES.find(c => c.code === code);
                        return (
                          <option key={code} value={code}>
                            {code} ({curr?.symbol})
                          </option>
                        );
                      })}
                      
                      <option disabled>──────────</option>
                      
                      {/* All other currencies */}
                      {CURRENCIES
                        .filter(curr => !POPULAR_CURRENCIES.includes(curr.code))
                        .map(curr => (
                          <option key={curr.code} value={curr.code}>
                            {curr.code} ({curr.symbol})
                          </option>
                        ))
                      }
                    </select>
                  </div>
                </div>
              </div>
            </div>

            {/* Toggle fields section */}
            <div className="border-t pt-4">
              {Object.entries(roleData).map(([key, value]) => {
                // Skip fields that are part of the hero section
                if (['jobTitle', 'department', 'reportsTo', 'teamSize', 'workModel', 
                     'employmentType', 'roleContext', 'language', 'salary', 'currency'].includes(key)) {
                  return null;
                }

                // If the value is null, show as a clickable "add" button
                if (value === null) {
                  return (
                    <div 
                      key={key} 
                      className="inline-flex items-center gap-2 px-3 py-1.5 text-sm text-gray-600 
                               border-2 border-dashed border-gray-200 rounded-md cursor-pointer 
                               hover:bg-gray-50 transition-colors mr-2 mb-2"
                      onClick={() => toggleField(key)}
                    >
                      <Plus className="h-4 w-4" />
                      <span>Add {key.charAt(0).toUpperCase() + key.slice(1)}</span>
                    </div>
                  );
                }

                // Show regular field with remove option
                return (
                  <div key={key} className="mb-4">
                    <div className="flex items-center justify-between mb-1">
                      <label className="block text-sm font-medium text-gray-700">
                        {key.charAt(0).toUpperCase() + key.slice(1)}
                      </label>
                      <button 
                        className="text-gray-400 hover:text-red-500 transition-colors"
                        onClick={() => toggleField(key)}
                      >
                        <X className="h-4 w-4" />
                      </button>
                    </div>
                    <input
                      type="text"
                      value={value}
                      onChange={(e) => updateField('roleData', key, e.target.value)}
                      className="w-full p-2 border rounded-md focus:ring-2 focus:ring-blue-500 
                               focus:border-blue-500 text-sm"
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </CardContent>
      )}
    </Card>
  );
};

export default RoleOverview;
