import jsPDF from 'jspdf';
import { FACTOR_CATEGORIES, factors } from '../interviewFactorsList';

export const generateEvaluationPDF = (questions, formData) => {
  const doc = new jsPDF();
  let yPos = 20;
  const lineHeight = 7;
  const margin = 20;
  const pageWidth = 210;
  const contentWidth = pageWidth - (margin * 2);

  // Helper function for word wrapping
  const splitTextToSize = (text, width) => {
    return doc.splitTextToSize(text, width);
  };

  // First Page: Overall Evaluation
  // Header
  doc.setFontSize(16);
  doc.text('Candidate Evaluation Form', margin, yPos);
  yPos += lineHeight * 2;

  // Position and candidate info
  doc.setFontSize(12);
  doc.text(`Position: ${formData?.roleData?.jobTitle || 'Role'}`, margin, yPos);
  yPos += lineHeight * 2;

  doc.text('Candidate Name: _________________________', margin, yPos);
  yPos += lineHeight;
  doc.text('Interview Date: _________________________', margin, yPos);
  yPos += lineHeight;
  doc.text('Interviewer: _________________________', margin, yPos);
  yPos += lineHeight * 3;

  // Overall Evaluation Section
  doc.setFontSize(14);
  doc.setFont(undefined, 'bold');
  doc.text('Overall Evaluation', margin, yPos);
  yPos += lineHeight * 1.5;

  doc.setFontSize(10);
  doc.setFont(undefined, 'normal');
  const evaluationText = splitTextToSize(
    'Please provide your overall assessment of the candidate based on all evaluation criteria.',
    contentWidth
  );
  doc.text(evaluationText, margin, yPos);
  yPos += lineHeight * evaluationText.length + lineHeight;

  doc.setFontSize(12);
  doc.text('Final Score (1-5): _____', margin, yPos);
  yPos += lineHeight * 2;

  doc.text('Recommendation:', margin, yPos);
  yPos += lineHeight;
  doc.text('□ Strongly Recommend', margin + 5, yPos);
  yPos += lineHeight;
  doc.text('□ Recommend', margin + 5, yPos);
  yPos += lineHeight;
  doc.text('□ Do Not Recommend', margin + 5, yPos);
  yPos += lineHeight * 2;

  doc.text('Additional Comments:', margin, yPos);
  yPos += lineHeight;
  doc.text('________________________________', margin, yPos);
  yPos += lineHeight;
  doc.text('________________________________', margin, yPos);

  // Start new page for detailed evaluations
  doc.addPage();
  yPos = 20;

  // Add category evaluations
  Object.entries(FACTOR_CATEGORIES).forEach(([categoryId, category]) => {
    // Check if we need a new page
    if (yPos > 250) {
      doc.addPage();
      yPos = 20;
    }

    doc.setFontSize(14);
    doc.setFont(undefined, 'bold');
    doc.text(`${category.label} Evaluation`, margin, yPos);
    yPos += lineHeight;

    doc.setFontSize(10);
    doc.setFont(undefined, 'normal');
    const description = splitTextToSize(category.description, contentWidth);
    doc.text(description, margin, yPos);
    yPos += lineHeight * description.length + lineHeight;

    doc.text('Score (1-5): _____', margin, yPos);
    yPos += lineHeight;
    doc.text('Comments: ________________________________', margin, yPos);
    yPos += lineHeight * 2;
  });

  // Add questions evaluation
  questions.forEach((question, index) => {
    // Check if we need a new page
    if (yPos > 250) {
      doc.addPage();
      yPos = 20;
    }

    const factor = factors.find(f => f.id === question.type);
    const category = factor ? FACTOR_CATEGORIES[factor.category] : null;
    const categoryLabel = category?.label || factor?.label || 'General';

    doc.setFontSize(12);
    doc.setFont(undefined, 'bold');
    doc.text(`Question ${index + 1}: ${categoryLabel}`, margin, yPos);
    yPos += lineHeight;

    doc.setFont(undefined, 'normal');
    const questionText = splitTextToSize(question.text, contentWidth);
    doc.text(questionText, margin, yPos);
    yPos += lineHeight * questionText.length + lineHeight;

    doc.setFontSize(10);
    doc.text('Evaluation Criteria:', margin, yPos);
    yPos += lineHeight;

    if (Array.isArray(question.description)) {
      question.description.forEach(point => {
        const bulletPoint = splitTextToSize(`• ${point}`, contentWidth - 5);
        doc.text(bulletPoint, margin + 5, yPos);
        yPos += lineHeight * bulletPoint.length;
      });
    } else {
      const description = splitTextToSize(`• ${question.description}`, contentWidth - 5);
      doc.text(description, margin + 5, yPos);
      yPos += lineHeight * description.length;
    }

    doc.text('Score (1-5): _____', margin, yPos);
    yPos += lineHeight;
    doc.text('Notes: ________________________________', margin, yPos);
    yPos += lineHeight * 2;
  });

  // Save the PDF
  const jobTitle = (formData?.roleData?.jobTitle || 'role').replace(/[^a-z0-9]/gi, '-').toLowerCase();
  const companyName = (formData?.companyData?.name || 'company').replace(/[^a-z0-9]/gi, '-').toLowerCase();
  const fileName = `candidate-evaluation-${companyName}-${jobTitle}.pdf`;
  doc.save(fileName);
}; 