import React from 'react';
import { AlertCircle } from 'lucide-react';
import { QUESTION_TYPES } from '../utils/questionTypeStyles';

// Add type info directly in the component
const getTypeInfo = (type) => {
  const typeInfo = {
    warmup: {
      type: 'Warm-up',
      max: 15,
      tip: 'Keep it light and conversational'
    },
    background: {
      type: 'Background',
      max: 20,
      tip: 'Focus on relevant career history'
    },
    technical: {
      type: 'Technical',
      max: 20,
      tip: 'Be specific about skills required'
    },
    behavioral: {
      type: 'Behavioral',
      max: 30,
      tip: 'Focus on specific past experiences'
    },
    situational: {
      type: 'Situational',
      max: 25,
      tip: 'Present clear hypothetical scenarios'
    },
    cultural: {
      type: 'Cultural',
      max: 20,
      tip: 'Focus on values and team dynamics'
    },
    closing: {
      type: 'Closing',
      max: 15,
      tip: 'Keep it open-ended and engaging'
    }
  };
  return typeInfo[type] || typeInfo.behavioral;
};

const QuestionWarnings = ({
  wordCount,
  isQuestionTooLong,
  isQuestionComplex,
  questionId,
  questionType,
  activeTooltip,
  setActiveTooltip,
}) => {
  const typeInfo = getTypeInfo(questionType);
  
  return (
    <div className="flex items-center gap-2">
      <div className="relative">
        <div 
          onClick={() => setActiveTooltip(activeTooltip === `wordcount-${questionId}` ? null : `wordcount-${questionId}`)}
          className={`text-xs px-1.5 py-0.5 rounded ${
            isQuestionTooLong
              ? 'bg-orange-50 text-orange-600 cursor-help hover:bg-orange-100'
              : wordCount > 0
                ? 'bg-green-50 text-green-600'
                : 'bg-gray-50 text-gray-600'
          }`}
        >
          {wordCount} words
        </div>
        {isQuestionTooLong && activeTooltip === `wordcount-${questionId}` && (
          <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-gray-900 text-white text-xs rounded whitespace-nowrap">
            <div>{`Question is too long (${wordCount} words).`}</div>
            <div className="mt-1">{`${typeInfo.type} questions should be ${typeInfo.max} words or less.`}</div>
            <div className="mt-1 text-gray-300">{typeInfo.tip}</div>
          </div>
        )}
      </div>
      
      {isQuestionComplex && (
        <div className="relative">
          <div 
            onClick={() => setActiveTooltip(activeTooltip === `complex-${questionId}` ? null : `complex-${questionId}`)}
            className="text-xs px-1.5 py-0.5 rounded bg-orange-50 text-orange-600 cursor-help hover:bg-orange-100"
          >
            <AlertCircle className="h-3.5 w-3.5 inline-block mr-1" />
            complex question
          </div>
          {activeTooltip === `complex-${questionId}` && (
            <div className="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-2 px-3 py-2 bg-gray-900 text-white text-xs rounded whitespace-nowrap">
              <div className="font-medium mb-1">Complex Question</div>
              <div className="text-gray-300">Consider breaking this into simpler questions to get clearer responses.</div>
              <div className="mt-1 text-gray-400 italic text-[10px]">Focused questions help candidates provide more specific answers.</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default QuestionWarnings; 