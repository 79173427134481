import { normalizeText, createValidationTip } from './validationUtils';

// Comprehensive list of compliance stopwords organized by category
const COMPLIANCE_STOPWORDS = {
  familyStatus: [
    'child', 'children', 'kids', 'baby', 'stepchildren', 'adoption', 'foster care',
    'single parent', 'guardian', 'caregiver', 'homemaker', 'dependents', 'family'
  ],
  
  pregnancy: [
    'pregnancy', 'pregnant', 'expecting', 'breastfeeding', 'lactation', 'maternity',
    'paternity', 'fertility', 'conception', 'ivf', 'in vitro',
    'breast pump', 'family planning'
  ],
  
  maritalStatus: [
    'marriage', 'marital', 'married', 'single', 'husband', 'wife', 'spouse',
    'widow', 'widower', 'partner', 'domestic partner', 'civil union',
    'relationship status', 'significant other'
  ],
  
  healthMedical: [
    'health condition', 'medical history', 'sick', 'illness', 'disability', 'disabled',
    'chronic condition', 'mental health', 'stress', 'anxiety', 'depression',
    'therapy', 'counseling', "doctor's visits", 'surgery', 'recovering',
    'injury', 'injured', 'weight', 'obesity', 'contraception', 'miscarriage',
    'abortion', 'menopause', 'menstruation', 'periods', 'hormonal'
  ],
  
  ageAppearance: [
    'age', 'date of birth', 'birth year', 'senior citizen', 'retirement',
    'young', 'youth', 'old', 'elderly', 'looks', 'appearance', 'attractive',
    'ugly', 'grey hair', 'wrinkles', 'bald', 'balding'
  ],
  
  lifestyle: [
    'smoking', 'smoker', 'alcohol', 'drinking', 'drugs', 'substance use',
    'gambling', 'partying', 'hobbies', 'diet', 'vegetarian', 'vegan',
    'religion', 'religious beliefs', 'faith', 'prayers', 'praying',
    'worship', 'church', 'mosque', 'temple'
  ],
  
  politicalBeliefs: [
    'political views', 'political party', 'voting', 'voter'
  ],
  
  demographicBackground: [
    'ethnic background', 'race', 'color', 'skin tone', 'origin', 'nationality',
    'citizenship', 'immigration', 'immigrant', 'visa', 'asylum', 'asylum seeker',
    'work permit', 'refugee'
  ],
  
  financialLegal: [
    'financial', 'credit', 'debt', 'bankruptcy',
    'lawsuit', 'legal', 'arrest', 'conviction', 'parole',
    'felony', 'misdemeanor', 'alimony', 'child support'
  ],
  
  genderOrientation: [
    'gender', 'male', 'female', 'boy', 'girl', 'transgender', 'trans',
    'non-binary', 'gender identity', 'pronouns', 'sexual', 'sex',
    'gay', 'lesbian', 'bisexual', 'queer', 'straight', 'heterosexual',
    'homosexual'
  ],
  
  workLifeBalance: [
    'personal commitments', 'flexibility', 'travel restrictions',
    'weekend commitments', 'holiday preferences', 'time off',
    'work-life balance', 'childcare', 'babysitter', 'nanny', 'daycare',
    'nursery', 'school drop-off'
  ],
  
  livingArrangements: [
    'homeowner', 'rent', 'renter', 'living', 'roommate',
    'neighborhood', 'distance', 'commute',
    'relocation', 'alone', 'home', 'settle'
  ]
};

// Flatten categories for searching
const ALL_STOPWORDS = Object.values(COMPLIANCE_STOPWORDS).flat();

export const findComplianceIssues = (text) => {
  if (!text || text.trim().length < 3) return false;

  const normalizedText = normalizeText(text);
  
  // Check for stop words using word boundaries
  const foundStopWords = ALL_STOPWORDS.filter(word => {
    // Create a regex with word boundaries
    const wordRegex = new RegExp(`\\b${word.toLowerCase()}\\b`);
    return wordRegex.test(normalizedText);
  });

  if (foundStopWords.length > 0) {
    // Find the category of the violation
    for (const [category, words] of Object.entries(COMPLIANCE_STOPWORDS)) {
      if (words.some(word => foundStopWords.includes(word))) {
        return {
          type: category,
          words: foundStopWords
        };
      }
    }
  }

  return false;
};

export const getComplianceTip = (text) => {
  const issues = findComplianceIssues(text);
  
  const tips = {
    familyStatus: {
      title: "Family Status Query",
      tip: "Questions about family status or caregiving responsibilities are not permitted.",
      example: "Focus on professional qualifications and job-related capabilities."
    },
    pregnancy: {
      title: "Pregnancy-Related Query",
      tip: "Questions about pregnancy, maternity, or family planning are prohibited.",
      example: "Discuss professional goals and career aspirations instead."
    },
    maritalStatus: {
      title: "Marital Status Query",
      tip: "Questions about marital or relationship status should be avoided.",
      example: "Keep focus on professional experience and qualifications."
    },
    healthMedical: {
      title: "Health/Medical Query",
      tip: "Questions about health status or medical conditions are not permitted.",
      example: "You may ask about ability to perform specific job functions."
    },
    ageAppearance: {
      title: "Age/Appearance Query",
      tip: "Questions about age or physical appearance are discriminatory.",
      example: "Focus on experience and qualifications rather than age or looks."
    },
    lifestyle: {
      title: "Lifestyle Query",
      tip: "Personal lifestyle choices and habits should not be discussed.",
      example: "Keep questions focused on professional capabilities."
    },
    politicalBeliefs: {
      title: "Political Views Query",
      tip: "Questions about political beliefs or affiliations are not appropriate.",
      example: "Maintain focus on job-related topics only."
    },
    demographicBackground: {
      title: "Demographic Query",
      tip: "Questions about race, ethnicity, or national origin are prohibited.",
      example: "Focus only on work authorization if relevant to the role."
    },
    financialLegal: {
      title: "Financial/Legal Query",
      tip: "Questions about financial or legal history should be avoided.",
      example: "Discuss professional experience and work history instead."
    },
    genderOrientation: {
      title: "Gender/Orientation Query",
      tip: "Questions about gender identity or sexual orientation are prohibited.",
      example: "Focus solely on professional qualifications."
    },
    workLifeBalance: {
      title: "Personal Schedule Query",
      tip: "Questions about personal commitments or arrangements are not appropriate.",
      example: "Discuss specific job schedule requirements instead."
    },
    livingArrangements: {
      title: "Living Situation Query",
      tip: "Questions about living arrangements or housing are not appropriate.",
      example: "If location is relevant, ask about ability to work at specific location."
    }
  };

  return createValidationTip(issues?.type, tips);
};

export const getHighlightedStopWords = (text) => {
  const issues = findComplianceIssues(text);
  return issues ? issues.words : [];
}; 