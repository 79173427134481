import React, { useState, useEffect } from 'react';
import { 
  BarChart2, 
  ChevronDown, 
  ChevronUp, 
  Sparkles, 
  Trash2, 
  PlusCircle,
  ArrowRight,
  ChevronLeft,
  ChevronRight
} from 'lucide-react';
import { 
  Card, 
  CardHeader, 
  CardTitle, 
  CardContent, 
  CardSubHeader,
  GradientButton 
} from './CardComponents';
import { fetchQuickAddsOutcomes } from '../utils/fetchQuickAddsOutcomes';
import { useFormContext } from '../contexts/FormContext';
import { showToast, TOAST_TYPES } from './common/ToastManager';
import QuickAddPanel from './common/QuickAddPanel';

// Add this near the top of the file, after imports
const loaderGifUrl = '/loader.gif';

const Outcomes = () => {
  const { formData, updateOutcomes } = useFormContext();
  const [outcomes, setOutcomes] = useState(Array.isArray(formData.outcomes) ? formData.outcomes : []);
  const [quickAddOutcomes, setQuickAddOutcomes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [isQuickAddCollapsed, setIsQuickAddCollapsed] = useState(false);

  // Update context only when outcomes actually change
  useEffect(() => {
    const currentOutcomes = formData.outcomes || [];
    const outcomesChanged = JSON.stringify(currentOutcomes) !== JSON.stringify(outcomes);
    
    if (outcomesChanged) {
      updateOutcomes(outcomes);
    }
  }, [outcomes]); // Only depend on outcomes

  // Sync local state when formData changes externally
  useEffect(() => {
    const externalOutcomes = formData.outcomes || [];
    const localOutcomes = outcomes;
    
    if (JSON.stringify(externalOutcomes) !== JSON.stringify(localOutcomes)) {
      setOutcomes(externalOutcomes);
    }
  }, [formData.outcomes]);

  // Add timeframe options
  const timeframeOptions = [
    { value: 'first_week', label: 'First Week' },
    { value: 'first_month', label: 'First Month' },
    { value: 'three_months', label: '3 Months' },
    { value: 'six_months', label: '6 Months' },
    { value: 'yearly', label: 'Yearly' }
  ];

  // Add helper function for timeframe labels
  const getTimeframeLabel = (value) => {
    const option = timeframeOptions.find(opt => opt.value === value);
    return option ? option.label : value;
  };

  // Add function to generate more quick adds
  const handleGenerateMoreQuickAdds = async () => {
    setIsLoading(true);
    try {
      showToast('Generating more suggestions...', TOAST_TYPES.INFO);
      const moreSuggestions = await fetchQuickAddsOutcomes(
        formData.companyData, 
        formData.roleData, 
        formData.timelines
      );
      
      if (moreSuggestions?.length > 0) {
        setQuickAddOutcomes(moreSuggestions);
        showToast('Generated more suggestions! ✨', TOAST_TYPES.SUCCESS);
      } else {
        showToast('No additional suggestions generated.', TOAST_TYPES.WARNING);
      }
    } catch (error) {
      console.error('Error generating more suggestions:', error);
      showToast('Failed to generate more suggestions', TOAST_TYPES.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleGenerateQuickAdds = async (e) => {
    e?.stopPropagation();
    setIsLoading(true);
    try {
      showToast('Generating success outcomes...', TOAST_TYPES.INFO);
      
      const mainOutcomes = await fetchQuickAddsOutcomes(formData.companyData, formData.roleData, formData.timelines);
      
      if (mainOutcomes?.length > 0) {
        // Convert to array format
        const newOutcomes = mainOutcomes.map(item => ({
          id: `outcome_${Date.now()}_${Math.random()}`,
          text: item.outcome,
          timeframe: item.timeframe
        }));
        
        setOutcomes(prev => [...prev, ...newOutcomes]);
        setIsExpanded(true);
        showToast('Generated outcomes successfully! ✨', TOAST_TYPES.SUCCESS);
      } else {
        showToast('No outcomes generated.', TOAST_TYPES.WARNING);
      }
    } catch (error) {
      showToast('Failed to generate outcomes', TOAST_TYPES.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddQuickAdd = (quickAddItem) => {
    setOutcomes(prev => [
      ...prev,
      {
        id: `outcome_${Date.now()}`,
        text: quickAddItem.outcome,
        timeframe: quickAddItem.timeframe
      }
    ]);
  };

  const handleUpdateOutcome = (id, key, value) => {
    setOutcomes(prev => 
      prev.map(item => item.id === id ? { ...item, [key]: value } : item)
    );
  };

  const handleDeleteOutcome = (id) => {
    setOutcomes(prev => prev.filter(item => item.id !== id));
  };

  const handleAddOutcome = () => {
    setOutcomes(prev => [
      ...prev,
      {
        id: `outcome_${Date.now()}`,
        text: '',
        timeframe: ''
      }
    ]);
  };

  const handleReorderOutcome = (currentId, direction) => {
    const currentIndex = outcomes.findIndex(item => item.id === currentId);
    const newIndex = currentIndex + direction;
    
    if (newIndex >= 0 && newIndex < outcomes.length) {
      const newList = [...outcomes];
      const [movedItem] = newList.splice(currentIndex, 1);
      newList.splice(newIndex, 0, movedItem);
      setOutcomes(newList);
    }
  };

  return (
    <Card className="max-w-5xl mx-auto">
      <CardHeader 
        data={{ outcomes }}
        requiredFields={['outcomes']}
        isExpanded={isExpanded}
        onClick={() => setIsExpanded(!isExpanded)}
        inputSection={
          <div className="flex items-center gap-2">
            <GradientButton
              onClick={(e) => {
                e.stopPropagation();
                handleGenerateQuickAdds();
              }}
              disabled={isLoading}
              icon={Sparkles}
              loadingText="Generating"
              isLoading={isLoading}
              className="!bg-gradient-to-r !from-blue-600 !to-blue-500 hover:!from-blue-700 hover:!to-blue-600 !px-3"
            >
              Generate
            </GradientButton>
          </div>
        }
      >
        <div className="flex items-center gap-2">
          <BarChart2 className="h-5 w-5" />
          <div>
            <CardTitle>Success Outcomes</CardTitle>
            <CardSubHeader 
              count={outcomes.length || undefined}
              emptyText="Define measurable outcomes for different time periods"
            >
              outcomes defined
            </CardSubHeader>
          </div>
        </div>
      </CardHeader>

      {isExpanded && (
        <CardContent className="space-y-6">
          <div className={`grid ${isQuickAddCollapsed ? 'grid-cols-1' : 'grid-cols-3'} gap-6`}>
            {/* Left Column: Outcomes List */}
            <div className={isQuickAddCollapsed ? 'col-span-1' : 'col-span-2'}>
              {outcomes.map((item) => (
                <div key={item.id} className="flex items-center gap-3">
                  <input
                    type="text"
                    value={item.text}
                    onChange={(e) => handleUpdateOutcome(item.id, 'text', e.target.value)}
                    className="flex-1 p-2 border border-gray-300 rounded-md text-xs"
                    placeholder="What should be achieved?"
                  />
                  <select
                    value={item.timeframe}
                    onChange={(e) => handleUpdateOutcome(item.id, 'timeframe', e.target.value)}
                    className="p-2 border border-gray-300 rounded-md text-xs w-40"
                  >
                    <option value="">Select timeframe</option>
                    {timeframeOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <div className="flex items-center gap-1">
                    <div className="flex flex-col">
                      <button
                        onClick={() => handleReorderOutcome(item.id, -1)}
                        disabled={item.id === outcomes[0].id}
                        className="p-0.5 rounded hover:bg-gray-100 disabled:opacity-50 disabled:hover:bg-transparent"
                      >
                        <ChevronUp className="h-3.5 w-3.5 text-gray-600" />
                      </button>
                      <button
                        onClick={() => handleReorderOutcome(item.id, 1)}
                        disabled={item.id === outcomes[outcomes.length - 1].id}
                        className="p-0.5 rounded hover:bg-gray-100 disabled:opacity-50 disabled:hover:bg-transparent"
                      >
                        <ChevronDown className="h-3.5 w-3.5 text-gray-600" />
                      </button>
                    </div>
                    <button
                      onClick={() => handleDeleteOutcome(item.id)}
                      className="p-1 rounded hover:bg-red-100"
                    >
                      <Trash2 className="h-4 w-4 text-red-600" />
                    </button>
                  </div>
                </div>
              ))}
              <button
                onClick={handleAddOutcome}
                className="flex items-center gap-1.5 text-blue-600 hover:text-blue-800 mt-4 text-sm"
              >
                <PlusCircle className="h-4 w-4" />
                Add Outcome
              </button>
            </div>

            {/* Replace the Quick Add section with QuickAddPanel */}
            <QuickAddPanel
              isCollapsed={isQuickAddCollapsed}
              onCollapse={setIsQuickAddCollapsed}
              isLoading={isLoading}
              items={quickAddOutcomes}
              onItemClick={handleAddQuickAdd}
              onReload={handleGenerateMoreQuickAdds}
              renderItem={(item, index) => (
                <button
                  key={index}
                  onClick={() => handleAddQuickAdd(item)}
                  className="w-full text-left flex flex-col gap-1 p-2 rounded border border-gray-200 bg-white hover:border-blue-500 hover:bg-blue-50 transition-colors"
                >
                  <span className="font-medium text-xs">{item.outcome}</span>
                  <span className="text-xs text-gray-500">
                    {getTimeframeLabel(item.timeframe)}
                  </span>
                </button>
              )}
            />
          </div>
        </CardContent>
      )}
    </Card>
  );
};

export default Outcomes;
