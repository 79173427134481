import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { ToasterComponent } from './components/common/ToastManager';
import RoleOverview from './components/RoleOverview';
import RoleDesignerHero from './components/RoleDesignerHero';
import CompanyProfile from './components/CompanyProfile';
import Timeline from './components/Timeline';
import Responsibilities from './components/Responsibilities';
import Outcomes from './components/Outcomes';
import SwaggerDocs from './pages/SwaggerDocs';
import RoleViewerPage from './pages/RoleViewerPage';
import { FormProvider, useFormContext } from './contexts/FormContext';
import OutputsSection from './components/outputs/OutputsSection';
import ModeToggle from './components/ModeToggle';
import ProModeSection from './components/ProModeSection';
import Header from './components/Header';

// Define basename for consistent routing
export const APP_BASENAME = '/role-designer';

// Near the top of the file, after imports
console.log('[App] Environment check:', {
  nodeEnv: process.env.NODE_ENV,
  basename: process.env.PUBLIC_URL || '/role-designer',
  hasApiKey: !!process.env.REACT_APP_API_KEY,
  hasProjectRef: !!process.env.REACT_APP_SUPABASE_PROJECT_REF
});

// Create a separate component for the main app content
const AppContent = () => {
  const { isProMode, toggleProMode } = useFormContext();

  return (
    <div className="App">
      <ToasterComponent />
      <div className="min-h-screen bg-gray-50 py-8">
        <Header />
        
        <div className="space-y-4">
          <RoleDesignerHero />
          <CompanyProfile />
          <RoleOverview />
          {isProMode ? (
            <>
              <Timeline />
              <div className="max-w-5xl mx-auto space-y-4">
                <Responsibilities />
                <Outcomes />
              </div>
            </>
          ) : (
            <ProModeSection onEnableProMode={() => toggleProMode()} />
          )}
          <OutputsSection isProMode={isProMode} />
        </div>
      </div>
    </div>
  );
};

function App() {
  useEffect(() => {
    document.title = 'Role Designer | PredictHire';
  }, []);

  return (
    <Router>
      <FormProvider>
        <Routes>
          {/* Redirect root to /role-designer */}
          <Route path="/" element={<Navigate to={APP_BASENAME} replace />} />
          
          {/* Main app routes under /role-designer */}
          <Route path={APP_BASENAME}>
            <Route index element={<AppContent />} />
            <Route path="api-docs" element={<SwaggerDocs />} />
            <Route path="roles/:role" element={<RoleViewerPage />} />
          </Route>

          {/* Catch all other routes and redirect to /role-designer */}
          <Route path="*" element={<Navigate to={APP_BASENAME} replace />} />
        </Routes>
      </FormProvider>
    </Router>
  );
}

export default App;