import React from 'react';
import QuestionWarnings from './QuestionWarnings';
import ComplianceWarnings from './ComplianceWarnings';
import { getWordCount, isQuestionTooLong, isQuestionComplex } from '../utils/questionValidation';
import { FACTOR_CATEGORIES } from '../interviewFactorsList';

const QuestionControls = ({
  question,
  type,
  onTypeChange,
  activeTooltip,
  setActiveTooltip
}) => {
  const currentCategory = Object.entries(FACTOR_CATEGORIES).find(([_, category]) => 
    category.factors.includes(type)
  )?.[0] || 'technical';

  return (
    <div className="flex items-center justify-between">
      <div className="flex items-center gap-3">
        <select
          value={currentCategory}
          onChange={(e) => {
            const newCategory = e.target.value;
            const firstFactor = FACTOR_CATEGORIES[newCategory].factors[0];
            onTypeChange(firstFactor);
          }}
          className={`text-sm border rounded px-2 py-1.5 ${FACTOR_CATEGORIES[currentCategory]?.bgColor} ${FACTOR_CATEGORIES[currentCategory]?.textColor}`}
        >
          {Object.entries(FACTOR_CATEGORIES).map(([categoryId, category]) => (
            <option key={categoryId} value={categoryId}>
              {category.label}
            </option>
          ))}
        </select>

        <div className="flex items-center gap-2">
          <QuestionWarnings
            wordCount={getWordCount(question.text)}
            isQuestionTooLong={isQuestionTooLong(question.text, type)}
            isQuestionComplex={isQuestionComplex(question.text)}
            questionId={question.id}
            questionType={type}
            activeTooltip={activeTooltip}
            setActiveTooltip={setActiveTooltip}
          />
          
          <ComplianceWarnings
            text={question.text}
            questionId={question.id}
            activeTooltip={activeTooltip}
            setActiveTooltip={setActiveTooltip}
          />
        </div>
      </div>
    </div>
  );
};

export default QuestionControls; 