import ExcelJS from 'exceljs';
import { FACTOR_CATEGORIES } from '../interviewFactorsList';

export const generateComparisonMatrix = async (questions, formData) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Candidate Comparison');

  // Styling
  const headerStyle = {
    font: { bold: true, color: { argb: 'FFFFFFFF' } },
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FF2563EB' } },
    alignment: { vertical: 'middle', horizontal: 'center', wrapText: true }
  };

  const subHeaderStyle = {
    font: { bold: true },
    fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFF3F4F6' } },
    alignment: { vertical: 'middle', horizontal: 'left', wrapText: true }
  };

  // Set columns
  worksheet.columns = [
    { header: 'Evaluation Criteria', key: 'criteria', width: 30 },
    { header: 'Candidate 1', key: 'candidate1', width: 15 },
    { header: 'Candidate 2', key: 'candidate2', width: 15 },
    { header: 'Candidate 3', key: 'candidate3', width: 15 },
    { header: 'Notes', key: 'notes', width: 40 }
  ];

  // Style header row
  worksheet.getRow(1).eachCell(cell => {
    cell.style = headerStyle;
  });
  worksheet.getRow(1).height = 30;

  let rowIndex = 2;

  // Add category sections
  Object.entries(FACTOR_CATEGORIES).forEach(([categoryId, category]) => {
    // Category header
    const categoryRow = worksheet.addRow([category.label]);
    categoryRow.getCell(1).style = subHeaderStyle;
    worksheet.mergeCells(`A${rowIndex}:E${rowIndex}`);
    rowIndex++;

    // Category score row
    worksheet.addRow(['Score (1-5)']);
    worksheet.getRow(rowIndex).eachCell(cell => {
      cell.style = {
        font: { italic: true },
        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFFFF' } }
      };
    });
    rowIndex++;

    // Add empty row for spacing
    worksheet.addRow([]);
    rowIndex++;
  });

  // Add questions section
  worksheet.addRow(['Question-by-Question Evaluation']);
  worksheet.getRow(rowIndex).getCell(1).style = subHeaderStyle;
  worksheet.mergeCells(`A${rowIndex}:E${rowIndex}`);
  rowIndex++;

  questions.forEach((question, index) => {
    // Question text
    const questionRow = worksheet.addRow([`Q${index + 1}: ${question.text}`]);
    questionRow.getCell(1).style = {
      font: { bold: true },
      alignment: { wrapText: true }
    };
    worksheet.mergeCells(`A${rowIndex}:E${rowIndex}`);
    rowIndex++;

    // Score row
    worksheet.addRow(['Score (1-5)']);
    worksheet.getRow(rowIndex).eachCell(cell => {
      cell.style = {
        font: { italic: true },
        fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFFFF' } }
      };
    });
    rowIndex++;

    // Evaluation criteria
    if (question.description) {
      const criteria = Array.isArray(question.description) 
        ? question.description 
        : [question.description];
      
      criteria.forEach(point => {
        worksheet.addRow([`• ${point}`]);
        worksheet.getRow(rowIndex).getCell(1).style = {
          alignment: { wrapText: true }
        };
        rowIndex++;
      });
    }

    // Add empty row for spacing
    worksheet.addRow([]);
    rowIndex++;
  });

  // Add overall evaluation section
  worksheet.addRow(['Overall Evaluation']);
  worksheet.getRow(rowIndex).getCell(1).style = subHeaderStyle;
  worksheet.mergeCells(`A${rowIndex}:E${rowIndex}`);
  rowIndex++;

  // Final score row
  worksheet.addRow(['Final Score (1-5)']);
  worksheet.getRow(rowIndex).eachCell(cell => {
    cell.style = {
      font: { italic: true },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFFFF' } }
    };
  });
  rowIndex++;

  // Recommendation row
  worksheet.addRow(['Recommendation']);
  worksheet.getRow(rowIndex).eachCell(cell => {
    cell.style = {
      font: { italic: true },
      fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFFFFFFF' } }
    };
  });

  // Generate and download
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);
  
  const jobTitle = (formData?.roleData?.jobTitle || 'role').replace(/[^a-z0-9]/gi, '-').toLowerCase();
  const companyName = (formData?.companyData?.name || 'company').replace(/[^a-z0-9]/gi, '-').toLowerCase();
  const fileName = `candidate-comparison-${companyName}-${jobTitle}.xlsx`;

  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}; 