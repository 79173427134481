import React, { createContext, useContext, useState, useEffect } from 'react';
import { ROLE_DATA_STRUCTURE, getInitialState } from '../utils/dataStructure';
import { showToast, TOAST_TYPES } from '../components/common/ToastManager';

const FormContext = createContext();
const STORAGE_KEY = 'roleDesignerFormData';
const PRO_MODE_KEY = 'roleDesignerProMode';

const hasProContent = (formData) => {
  // Check if there's any content in pro features
  const hasTimelines = formData.timelines?.steps?.length > 0;
  const hasResponsibilities = formData.responsibilities?.length > 0;
  const hasOutcomes = formData.outcomes?.length > 0;
  const hasInterviewQuestions = formData.interviewQuestions?.length > 0;

  return hasTimelines || hasResponsibilities || hasOutcomes || hasInterviewQuestions;
};

export const FormProvider = ({ children }) => {
  const [formData, setFormData] = useState(() => {
    const savedData = localStorage.getItem(STORAGE_KEY);
    const parsedData = savedData ? JSON.parse(savedData) : getInitialState();
    return parsedData;
  });

  const [isProMode, setIsProMode] = useState(() => {
    // Check localStorage first
    const savedProMode = localStorage.getItem(PRO_MODE_KEY);
    const savedProModeValue = savedProMode ? JSON.parse(savedProMode) : false;
    
    // Check if viewing shared role
    const isViewingSharedRole = window.location.pathname.includes('/roles/');
    
    // Check if there's pro content in the form data
    const savedData = localStorage.getItem(STORAGE_KEY);
    const parsedData = savedData ? JSON.parse(savedData) : getInitialState();
    const hasExistingProContent = hasProContent(parsedData);
    
    // Enable pro mode if any condition is true
    return savedProModeValue || isViewingSharedRole || hasExistingProContent;
  });

  const toggleProMode = () => {
    setIsProMode(prev => {
      const newValue = !prev;
      
      // If trying to disable pro mode while having pro content, show warning
      if (!newValue && hasProContent(formData)) {
        showToast(
          'Warning: Pro content will be hidden but not deleted when pro mode is disabled', 
          TOAST_TYPES.WARNING
        );
      }
      
      localStorage.setItem(PRO_MODE_KEY, JSON.stringify(newValue));
      return newValue;
    });
  };

  const setProModeEnabled = (enabled) => {
    // If trying to disable pro mode while having pro content, show warning
    if (!enabled && hasProContent(formData)) {
      showToast(
        'Warning: Pro content will be hidden but not deleted when pro mode is disabled', 
        TOAST_TYPES.WARNING
      );
    }
    
    setIsProMode(enabled);
    localStorage.setItem(PRO_MODE_KEY, JSON.stringify(enabled));
  };

  useEffect(() => {
    localStorage.setItem(PRO_MODE_KEY, JSON.stringify(isProMode));
  }, [isProMode]);

  useEffect(() => {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(formData));
  }, [formData]);

  useEffect(() => {
    const handleUrlChange = () => {
      const isViewingSharedRole = window.location.pathname.includes('/roles/');
      const savedProMode = localStorage.getItem(PRO_MODE_KEY);
      const wasProMode = savedProMode ? JSON.parse(savedProMode) : false;
      
      if (isViewingSharedRole || wasProMode) {
        setIsProMode(true);
      }
    };

    window.addEventListener('popstate', handleUrlChange);
    
    handleUrlChange();

    return () => window.removeEventListener('popstate', handleUrlChange);
  }, []);

  const updateCompanyData = (companyData) => {
    setFormData(prev => ({
      ...prev,
      companyData
    }));
  };

  const updateRoleData = (roleData) => {
    setFormData(prev => ({
      ...prev,
      roleData
    }));
  };

  const updateTimelines = (updates) => {
    setFormData(prev => ({
      ...prev,
      timelines: {
        ...prev.timelines,
        ...updates
      }
    }));
  };

  const updateResponsibilities = (responsibilities) => {
    setFormData(prev => ({
      ...prev,
      responsibilities
    }));
  };

  const updateOutcomes = (outcomes) => {
    setFormData(prev => ({
      ...prev,
      outcomes
    }));
  };

  const updateField = (section, field, value) => {
    setFormData(prev => ({
      ...prev,
      [section]: {
        ...prev[section],
        [field]: value
      }
    }));
  };

  const updateOutput = (type, content) => {
    setFormData(prev => ({
      ...prev,
      outputs: {
        ...prev.outputs,
        [type]: content
      }
    }));
  };

  const updateInterviewQuestions = (questions) => {
    setFormData(prev => ({
      ...prev,
      interviewQuestions: questions
    }));
  };

  const canShareRole = () => {
    const hasCompanyName = formData.companyData && 
                          typeof formData.companyData.name === 'string' && 
                          formData.companyData.name.trim() !== '';
                          
    const hasJobTitle = formData.roleData && 
                       typeof formData.roleData.jobTitle === 'string' && 
                       formData.roleData.jobTitle.trim() !== '';

    console.log('Detailed shareability check:', {
      formDataExists: !!formData,
      companyDataExists: !!formData.companyData,
      roleDataExists: !!formData.roleData,
      companyNameType: typeof formData.companyData?.name,
      jobTitleType: typeof formData.roleData?.jobTitle,
      companyNameValue: formData.companyData?.name,
      jobTitleValue: formData.roleData?.jobTitle,
      hasCompanyName,
      hasJobTitle
    });
    
    return Boolean(hasCompanyName && hasJobTitle);
  };

  const value = {
    formData,
    isProMode,
    toggleProMode,
    setProModeEnabled,
    updateField,
    updateCompanyData,
    updateRoleData,
    updateTimelines,
    updateResponsibilities,
    updateOutcomes,
    updateOutput,
    updateInterviewQuestions,
    canShareRole
  };

  return (
    <FormContext.Provider value={value}>
      {children}
    </FormContext.Provider>
  );
};

export const useFormContext = () => {
  const context = useContext(FormContext);
  if (!context) {
    throw new Error('useFormContext must be used within a FormProvider');
  }
  return context;
};
