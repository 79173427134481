import { fetchFromChatGPT } from './fetchFromChatGPT';

export async function fetchRoleContent(roleContext) {
  try {
    const response = await fetchFromChatGPT({
      model: "gpt-4",
      messages: [
        {
          role: "system",
          content: "You are a helpful HR assistant that extracts role information from context."
        },
        {
          role: "user",
          content: `Based on this role context, provide likely values for the following fields in JSON format:
            jobTitle, department, reportsTo, teamSize, workModel, employmentType.
            
            Role Context: ${roleContext}`
        }
      ],
      temperature: 0.7
    });

    const suggestion = JSON.parse(response.choices[0].message.content);
    return suggestion;
  } catch (error) {
    console.error('Error fetching role content:', error);
    throw error;
  }
}
