import React, { useState } from 'react';
import { Card, CardHeader, CardTitle, CardContent, CardSubHeader, GradientButton } from '../../CardComponents';
import { FileText, Sparkles, Download, Bold, Italic, Heading1, Heading2, List, ListOrdered, Undo, Redo } from 'lucide-react';
import { useFormContext } from '../../../contexts/FormContext';
import { fetchFromChatGPT } from '../../../utils/fetchFromChatGPT';
import { showToast, TOAST_TYPES } from '../../common/ToastManager';
import createJobPostPrompt from './jobPostPrompt';
import { generateJobPostDOCX } from './utils/generateJobPostDOCX';
import { useEditor, EditorContent } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Heading from '@tiptap/extension-heading';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Text from '@tiptap/extension-text';
import History from '@tiptap/extension-history';

const MenuBar = ({ editor }) => {
  if (!editor) return null;

  return (
    <div className="flex items-center gap-2">
      <button
        onClick={() => editor.chain().focus().undo().run()}
        disabled={!editor.can().undo()}
        className={`p-1.5 hover:bg-gray-100 rounded-md tooltip ${!editor.can().undo() ? 'opacity-50' : ''}`}
        title="Undo"
      >
        <Undo className="h-4 w-4 text-gray-600" />
      </button>
      <button
        onClick={() => editor.chain().focus().redo().run()}
        disabled={!editor.can().redo()}
        className={`p-1.5 hover:bg-gray-100 rounded-md tooltip ${!editor.can().redo() ? 'opacity-50' : ''}`}
        title="Redo"
      >
        <Redo className="h-4 w-4 text-gray-600" />
      </button>
      <div className="w-px h-6 bg-gray-200 mx-2" />
      <button
        onClick={() => editor.chain().focus().toggleBold().run()}
        className={`p-1.5 hover:bg-gray-100 rounded-md tooltip ${editor.isActive('bold') ? 'bg-gray-100' : ''}`}
        title="Bold"
      >
        <Bold className="h-4 w-4 text-gray-600" />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleItalic().run()}
        className={`p-1.5 hover:bg-gray-100 rounded-md tooltip ${editor.isActive('italic') ? 'bg-gray-100' : ''}`}
        title="Italic"
      >
        <Italic className="h-4 w-4 text-gray-600" />
      </button>
      <div className="w-px h-6 bg-gray-200 mx-2" />
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={`p-1.5 hover:bg-gray-100 rounded-md tooltip ${editor.isActive('heading', { level: 1 }) ? 'bg-gray-100' : ''}`}
        title="Heading 1"
      >
        <Heading1 className="h-4 w-4 text-gray-600" />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={`p-1.5 hover:bg-gray-100 rounded-md tooltip ${editor.isActive('heading', { level: 2 }) ? 'bg-gray-100' : ''}`}
        title="Heading 2"
      >
        <Heading2 className="h-4 w-4 text-gray-600" />
      </button>
      <div className="w-px h-6 bg-gray-200 mx-2" />
      <button
        onClick={() => editor.chain().focus().toggleBulletList().run()}
        className={`p-1.5 hover:bg-gray-100 rounded-md tooltip ${editor.isActive('bulletList') ? 'bg-gray-100' : ''}`}
        title="Bullet List"
      >
        <List className="h-4 w-4 text-gray-600" />
      </button>
      <button
        onClick={() => editor.chain().focus().toggleOrderedList().run()}
        className={`p-1.5 hover:bg-gray-100 rounded-md tooltip ${editor.isActive('orderedList') ? 'bg-gray-100' : ''}`}
        title="Numbered List"
      >
        <ListOrdered className="h-4 w-4 text-gray-600" />
      </button>
    </div>
  );
};

const JobPostOutput = () => {
  const { formData, updateOutput } = useFormContext();
  const [isLoading, setIsLoading] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const editor = useEditor({
    extensions: [
      StarterKit.configure({
        heading: {
          levels: [1, 2]
        },
        bulletList: true,
        orderedList: true,
        history: true,
      }),
    ],
    editorProps: {
      attributes: {
        class: 'prose prose-sm max-w-none focus:outline-none min-h-[400px] prose-headings:mt-4 prose-headings:mb-2 prose-h1:text-2xl prose-h1:font-bold prose-h1:border-b prose-h1:pb-2 prose-h1:border-gray-200 prose-h2:text-xl prose-h2:font-semibold prose-p:my-2 prose-ul:my-2 prose-ul:list-disc prose-ul:pl-4 prose-ol:my-2 prose-ol:list-decimal prose-ol:pl-4',
      },
    },
    content: formData.outputs?.jobPost || '',
    onUpdate: ({ editor }) => {
      const html = editor.getHTML();
      updateOutput('jobPost', html);
    },
  });

  const generateJobPost = async (e) => {
    e?.stopPropagation();
    setIsLoading(true);
    try {
      const prompt = createJobPostPrompt(formData);
      const response = await fetchFromChatGPT(prompt);
      const generatedPost = response.choices[0].message.content;
      
      editor?.commands.setContent(generatedPost);
      updateOutput('jobPost', generatedPost);
      setIsExpanded(true);
      showToast('Job post generated successfully! ✨', TOAST_TYPES.SUCCESS);
    } catch (error) {
      console.error('Error generating job post:', error);
      showToast('Failed to generate job post', TOAST_TYPES.ERROR);
    } finally {
      setIsLoading(false);
    }
  };

  const handleExportDOCX = async (e) => {
    e?.stopPropagation();
    
    if (!formData.outputs?.jobPost) {
      showToast('Generate a job post first', TOAST_TYPES.WARNING);
      return;
    }

    try {
      await generateJobPostDOCX(formData.outputs.jobPost, formData);
      showToast('Job post exported to DOCX', TOAST_TYPES.SUCCESS);
    } catch (error) {
      console.error('Error exporting to DOCX:', error);
      showToast('Failed to export DOCX', TOAST_TYPES.ERROR);
    }
  };

  return (
    <Card className="max-w-5xl mx-auto">
      <CardHeader 
        data={formData.outputs}
        requiredFields={['jobPost']}
        isExpanded={isExpanded}
        onClick={() => setIsExpanded(!isExpanded)}
        inputSection={
          <div className="flex items-center gap-2">
            {formData.outputs?.jobPost && (
              <button
                onClick={handleExportDOCX}
                className="flex items-center gap-1.5 px-3 py-2 text-sm text-blue-700 hover:bg-blue-50 rounded-md transition-colors border border-blue-200"
              >
                <Download className="h-4 w-4" />
                DOCX
              </button>
            )}
            <GradientButton
              onClick={generateJobPost}
              disabled={!formData.companyData?.name || !formData.roleData?.jobTitle || isLoading}
              icon={Sparkles}
              loadingText="Generating"
              isLoading={isLoading}
              className="!bg-gradient-to-r !from-blue-600 !to-blue-500 hover:!from-blue-700 hover:!to-blue-600 !px-3"
            >
              Generate
            </GradientButton>
          </div>
        }
      >
        <div className="flex items-center gap-2">
          <FileText className="h-5 w-5" />
          <div>
            <CardTitle>Job Post</CardTitle>
            <CardSubHeader 
              count={formData.outputs?.jobPost ? 1 : undefined}
              emptyText="Generate a comprehensive job post"
            >
              job post generated
            </CardSubHeader>
          </div>
        </div>
      </CardHeader>

      {isExpanded && (
        <CardContent className="space-y-6">
          <div className="bg-gray-50 p-4 rounded-lg">
            {formData.outputs?.jobPost ? (
              <div className="relative">
                <div className="mb-2 flex items-center justify-between bg-white border border-gray-200 rounded-t-md p-2">
                  <MenuBar editor={editor} />
                </div>
                <div className="w-full p-6 bg-white border border-gray-200 rounded-b-md">
                  <EditorContent 
                    editor={editor}
                    className="prose prose-sm max-w-none focus:outline-none prose-headings:mt-4 prose-headings:mb-2 prose-h1:text-2xl prose-h1:font-bold prose-h1:border-b prose-h1:pb-2 prose-h1:border-gray-200 prose-h2:text-xl prose-h2:font-semibold prose-p:my-2 prose-ul:my-2 prose-ul:list-disc prose-ul:pl-4 prose-ol:my-2 prose-ol:list-decimal prose-ol:pl-4"
                  />
                </div>
              </div>
            ) : (
              <p className="text-gray-500">
                Click generate to create a comprehensive job post based on your role details
              </p>
            )}
          </div>
        </CardContent>
      )}
    </Card>
  );
};

export default JobPostOutput;