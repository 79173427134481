const createJobPostPrompt = (formData) => {
  const {
    companyData,
    roleData,
    timelines,
    responsibilities,
    outcomes,
  } = formData;

  // Only include sections that have data
  const hasResponsibilities = Array.isArray(responsibilities) && responsibilities.length > 0;
  const hasTimeline = timelines?.steps?.length > 0;
  const hasOutcomes = Array.isArray(outcomes) && outcomes.length > 0;

  // Format responsibilities properly
  const formattedResponsibilities = hasResponsibilities 
    ? responsibilities.map(r => typeof r === 'object' ? r.text : r)
        .filter(Boolean)
        .map(r => `<li>${r}</li>`)
        .join('\n')
    : '';

  // Format timeline steps
  const formattedTimeline = hasTimeline
    ? timelines.steps.map(step => 
        `<h3>${step.phase} (${step.duration})</h3>
        <ul>
          ${step.activities?.map(activity => `<li>${activity}</li>`).join('\n') || ''}
        </ul>`
      ).join('\n')
    : '';

  // Format outcomes
  const outcomesByTimeframe = hasOutcomes
    ? outcomes.reduce((acc, outcome) => {
        const timeframe = outcome.timeframe || 'general';
        if (!acc[timeframe]) {
          acc[timeframe] = [];
        }
        acc[timeframe].push(outcome.text);
        return acc;
      }, {})
    : {};

  const formattedOutcomes = Object.entries(outcomesByTimeframe)
    .map(([timeframe, outcomes]) => 
      `<h3>${timeframe}</h3>
      <ul>
        ${outcomes.map(outcome => `<li>${outcome}</li>`).join('\n')}
      </ul>`
    ).join('\n');

  // Build the content sections conditionally
  const sections = [
    // Company Overview - Always include
    `<h1>Company Overview</h1>
    <p><strong>Company:</strong> ${companyData?.name}</p>
    <p><strong>Industry:</strong> ${companyData?.industry}</p>
    ${companyData?.description ? `<p>${companyData.description}</p>` : ''}`,

    // Company Culture - Only if exists
    companyData?.culture ? 
    `<h2>Company Culture</h2>
    <p>${companyData.culture}</p>` : '',

    // About the Role - Always include
    `<h1>About the Role</h1>
    <p><strong>Position:</strong> ${roleData?.jobTitle}</p>
    ${roleData?.location ? `<p><strong>Location:</strong> ${roleData.location}</p>` : ''}
    ${roleData?.employmentType ? `<p><strong>Employment Type:</strong> ${roleData.employmentType}</p>` : ''}
    ${roleData?.description ? `<p>${roleData.description}</p>` : ''}`,

    // Key Responsibilities - Only if exists
    hasResponsibilities ? 
    `<h1>Key Responsibilities</h1>
    <ul>${formattedResponsibilities}</ul>` : '',

    // Requirements - Only if exists
    roleData?.requirements ?
    `<h1>Requirements & Qualifications</h1>
    <ul>${roleData.requirements.split('\n').map(req => `<li>${req}</li>`).join('\n')}</ul>` : '',

    // Success Outcomes - Only if exists
    hasOutcomes ?
    `<h1>What Success Looks Like</h1>${formattedOutcomes}` : '',

    // Growth Journey - Only if exists
    hasTimeline ?
    `<h1>Growth Journey</h1>${formattedTimeline}` : ''
  ].filter(Boolean).join('\n\n');

  const prompt = {
    model: 'gpt-3.5-turbo',
    messages: [
      {
        role: 'system',
        content: `You are a professional job post writer specializing in creating compelling, detailed job posts that attract top talent. Focus on clarity, engagement, and highlighting growth opportunities.

Use proper HTML formatting and structure. Each section should be properly formatted with:
- Main sections using <h1> tags
- Subsections using <h2> tags
- Lists using <ul> or <ol> with <li> tags
- Paragraphs using <p> tags
- Important points using <strong> tags
- Emphasis using <em> tags

Enhance and expand upon the provided content while maintaining accuracy.`
      },
      {
        role: 'user',
        content: `${sections}

Please enhance this job post by:
1. Adding a compelling "What We Offer" section based on the company culture and role
2. Adding a clear "How to Apply" section with a call to action
3. Maintaining the existing information while making it more engaging
4. Using proper HTML formatting throughout
5. Ensuring all content is professional yet appealing
6. Adding appropriate transition text between sections`
      }
    ],
    max_tokens: 1500,
    temperature: 0.7
  };

  return prompt;
};

export default createJobPostPrompt; 