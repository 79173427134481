import jsPDF from 'jspdf';
import { getTypeLabel } from './questionTypeStyles';

export const generateInterviewPDF = (questions, formData) => {
  const doc = new jsPDF();
  let yPos = 20;
  const lineHeight = 7;
  const margin = 20;
  const pageWidth = 210;
  const contentWidth = pageWidth - (margin * 2);

  // Create sanitized filename
  const jobTitle = (formData?.roleData?.jobTitle || 'role').replace(/[^a-z0-9]/gi, '-').toLowerCase();
  const companyName = (formData?.companyData?.name || 'company').replace(/[^a-z0-9]/gi, '-').toLowerCase();
  const fileName = `interview-guide-${companyName}-${jobTitle}.pdf`;

  // Helper function for word wrapping
  const splitTextToSize = (text, width) => {
    return doc.splitTextToSize(text, width);
  };

  // Add header with fallbacks
  doc.setFontSize(16);
  doc.text(`Interview Guide: ${formData?.roleData?.jobTitle || 'Role'}`, margin, yPos);
  yPos += lineHeight * 2;

  // Add company info with fallbacks
  doc.setFontSize(12);
  doc.text(`Company: ${formData?.companyData?.name || 'Company'}`, margin, yPos);
  yPos += lineHeight;
  doc.text(`Department: ${formData?.roleData?.department || 'Department'}`, margin, yPos);
  yPos += lineHeight * 2;

  // Add questions in original order
  questions.forEach((question, index) => {
    // Check if we need a new page
    if (yPos > 270) {
      doc.addPage();
      yPos = 20;
    }

    // Question number and type
    doc.setFontSize(12);
    doc.setFont(undefined, 'bold');
    const questionHeader = `Question ${index + 1} (${getTypeLabel(question.category)})`;
    doc.text(questionHeader, margin, yPos);
    yPos += lineHeight * 1.5;

    // Question text
    doc.setFont(undefined, 'normal');
    const questionText = splitTextToSize(question.text, contentWidth);
    doc.text(questionText, margin, yPos);
    yPos += lineHeight * questionText.length + lineHeight;

    // Evaluation points
    doc.setFontSize(10);
    doc.text('Key points to evaluate:', margin, yPos);
    yPos += lineHeight;

    if (Array.isArray(question.description)) {
      question.description.forEach(point => {
        const bulletPoint = splitTextToSize(`• ${point}`, contentWidth - 5);
        doc.text(bulletPoint, margin + 5, yPos);
        yPos += lineHeight * bulletPoint.length;
      });
    } else {
      const description = splitTextToSize(`• ${question.description}`, contentWidth - 5);
      doc.text(description, margin + 5, yPos);
      yPos += lineHeight * description.length;
    }

    yPos += lineHeight * 2; // Add space between questions
  });

  // Save the PDF
  doc.save(fileName);
}; 