// src/components/CardComponents.jsx
import React, { forwardRef } from 'react';
import { Loader, ChevronUp, ChevronDown } from 'lucide-react';

// Add helper function
const hasAllRequiredFields = (data, requiredFields) => {
  if (!data) return false;
  return requiredFields.every(field => {
    // Handle nested fields (e.g. 'timelines.steps')
    const fieldParts = field.split('.');
    let value = data;
    
    // Traverse the nested fields
    for (const part of fieldParts) {
      value = value?.[part];
    }
    
    // Handle different types of values
    if (Array.isArray(value)) {
      return value.length > 0;
    } else if (typeof value === 'string') {
      return value.trim() !== '';
    } else if (value === null || value === undefined) {
      return false;
    }
    return Boolean(value);
  });
};

const Card = forwardRef(({ className = '', children, ...props }, ref) => {
  return (
    <div
      ref={ref}
      className={`bg-white rounded-lg border border-gray-200 shadow-sm w-full max-w-5xl ${className}`}
      {...props}
    >
      {children}
    </div>
  );
});

Card.displayName = 'Card';

const CardHeader = ({ 
  className = '', 
  children, 
  onClick, 
  data,
  requiredFields = [],
  isExpanded = false,
  inputSection,
  alwaysShowInput = false,
  ...props 
}) => {
  const isComplete = hasAllRequiredFields(data, requiredFields);

  // Input section visibility states:
  // 1. Company not entered (incomplete) - show input regardless of expanded state
  // 2. Company entered (complete) + closed - hide input unless alwaysShowInput is true
  // 3. Company entered (complete) + open - show input
  const shouldShowInput = !isComplete || isExpanded || alwaysShowInput;

  const handleClick = (e) => {
    if (onClick) {
      onClick(e);
    }
  };

  const headerClasses = `
    p-4 
    border-b 
    border-gray-200 
    cursor-pointer 
    transition-colors 
    ${isComplete ? 'bg-green-50' : ''} 
    ${className}
  `.trim();

  return (
    <div
      className={headerClasses}
      onClick={handleClick}
      {...props}
    >
      <div className="flex items-center justify-between gap-4">
        <div className={`flex items-center gap-2 flex-grow ${isComplete ? 'text-green-600' : ''}`}>
          {children}
        </div>
        {shouldShowInput && inputSection}
        <div className={`${isComplete ? 'text-green-600' : 'text-gray-500'}`}>
          {isExpanded ? (
            <ChevronUp className="h-5 w-5" />
          ) : (
            <ChevronDown className="h-5 w-5" />
          )}
        </div>
      </div>
    </div>
  );
};

const CardTitle = ({ className = '', children, ...props }) => {
  return (
    <h2
      className={`text-lg font-semibold text-gray-900 ${className}`}
      {...props}
    >
      {children}
    </h2>
  );
};

const CardSubHeader = ({ className = '', children, count, emptyText }) => {
  const baseStyles = "text-sm";
  const defaultTextColor = "text-gray-600";

  return (
    <p className={`${baseStyles} ${!className ? defaultTextColor : ''} ${className} truncate max-w-[500px]`}>
      {count !== undefined 
        ? `${count} ${children}`
        : emptyText || children}
    </p>
  );
};

const CardContent = ({ className = '', children, ...props }) => {
  return (
    <div
      className={`p-4 ${className}`}
      {...props}
    >
      {children}
    </div>
  );
};

const GradientButton = ({ 
  onClick, 
  disabled, 
  icon: Icon, 
  loadingText = "Loading...", 
  children,
  className = "",
  isLoading = false
}) => {
  return (
    <button
      onClick={onClick}
      disabled={disabled || isLoading}
      className={`
        flex items-center gap-2 px-4 py-2 rounded-md text-white 
        transition-all duration-200 transform hover:scale-105 active:scale-95 
        relative overflow-hidden
        ${disabled || isLoading
          ? 'bg-gray-400 cursor-not-allowed' 
          : 'bg-gradient-to-r from-[#7A1E8B] to-[#671778] hover:from-[#671778] hover:to-[#5c1569]'}
        ${className}
      `}
    >
      <div className="flex items-center gap-2 relative z-10">
        {isLoading ? (
          <>
            <img src={process.env.PUBLIC_URL + "/loader.gif"} alt="Loading..." className="h-4 w-4" />
            <span className="inline-flex items-center">
              {loadingText}
              <span className="ml-1 animate-pulse">...</span>
            </span>
          </>
        ) : (
          <>
            <Icon className="h-4 w-4 animate-pulse" />
            <span>{children}</span>
          </>
        )}
      </div>
      {!disabled && !isLoading && (
        <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white to-transparent opacity-20 transform -skew-x-12 transition-transform hover:translate-x-full duration-1000" />
      )}
    </button>
  );
};

export { Card, CardHeader, CardTitle, CardContent, CardSubHeader, GradientButton, hasAllRequiredFields };