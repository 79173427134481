import { fetchFromChatGPT } from './fetchFromChatGPT';
import { formatDataForAPI } from './dataStructure';

export const fetchQuickAddsOutcomes = async (companyData, roleData, timelines) => {
  const inputPayload = formatDataForAPI({ companyData, roleData, timelines });

  const prompt = `
    You are an assistant generating measurable success outcomes for a new hire. Based on the provided role and company details, return **exactly 4 outcomes** as a JSON array.

    Each outcome must include:
    - "outcome": A specific measurable achievement.
    - "timeframe": The timeframe for achieving the outcome (e.g., "first_week", "first_month", "three_months", "six_months").

    Strictly return a JSON array. Do not include any text, formatting, or comments outside the JSON.

    Role and company details:
    ${JSON.stringify(inputPayload, null, 2)}
  `;

  const payload = {
    model: 'gpt-3.5-turbo',
    messages: [
      { role: 'system', content: 'You are a helpful assistant generating structured outcomes for new hires.' },
      { role: 'user', content: prompt },
    ],
    max_tokens: 300,
    temperature: 0.7,
  };

  try {
    const response = await fetchFromChatGPT(payload);

    // Log raw GPT response for debugging
    console.log('Raw GPT Response:', response);

    const rawContent = response.choices[0].message.content.trim();

    // Attempt to parse the raw response
    let parsed;
    try {
      parsed = JSON.parse(rawContent);
    } catch (parseError) {
      console.warn('GPT response not valid JSON. Attempting to extract JSON from text.');

      // Attempt to extract JSON from text
      const jsonMatch = rawContent.match(/\[.*\]/s);
      if (jsonMatch) {
        parsed = JSON.parse(jsonMatch[0]);
      } else {
        throw new Error('No JSON array found in GPT response.');
      }
    }

    if (!Array.isArray(parsed)) {
      throw new Error('Parsed response is not an array.');
    }

    // Sanitize and reformat the parsed data
    const sanitizedData = parsed.map((item) => ({
      outcome: item.outcome || item.outcome_text || item.outcome_text || 'Not Specified',
      timeframe: item.timeframe || item.time_frame || 'Not Specified',
    }));

    // Filter out any invalid entries
    const validOutcomes = sanitizedData.filter(
      (item) => item.outcome && item.timeframe && item.outcome !== 'Not Specified' && item.timeframe !== 'Not Specified'
    );

    return validOutcomes;
  } catch (error) {
    console.error('Error fetching quick add outcomes:', error);
    return [];
  }
}
