import React, { useState, useEffect, useRef } from 'react';
import { ChevronDown, ChevronUp, Plus, Download, HelpCircle, Sparkles } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent, CardSubHeader, GradientButton } from '../../../components/CardComponents';
import GenerateQuestionsButton from './components/GenerateQuestionsButton';
import QuestionEditor from './components/QuestionEditor';
import QuickAddItem from './components/QuickAddItem';
import QuickAddPanel from './components/QuickAddPanel';
import { fetchQuickAddsInterviewQuestions } from '../../../utils/fetchQuickAddsInterviewQuestions';
import { useFormContext } from '../../../contexts/FormContext';
import { generateInterviewScheme } from '../../../utils/generateInterviewScheme';
import { generateInterviewPDF } from './utils/generateInterviewPDF';
import { generateInterviewDOCX } from './utils/generateInterviewDOCX';
import { generateEvaluationSchemeDOCX } from './evaluation/generateEvaluationScheme';
import { generateEvaluationPDF } from './evaluation/generateEvaluationPDF';
import DownloadButton from './components/DownloadButton';
import { generateComparisonMatrix } from './evaluation/generateComparisonMatrix';

const InterviewQuestionsAccordion = () => {
  const { formData, updateInterviewQuestions } = useFormContext();
  const accordionRef = useRef(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [movingIndex, setMovingIndex] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [quickAddQuestions, setQuickAddQuestions] = useState([]);
  const [activeTooltip, setActiveTooltip] = useState(null);
  const [editingDescription, setEditingDescription] = useState(null);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isQuickAddCollapsed, setIsQuickAddCollapsed] = useState(false);

  // Initialize questions with empty array if undefined
  const questions = formData?.interviewQuestions || [];

  useEffect(() => {
    if (questions.length > 0 && !isExpanded) {
      setIsExpanded(true);
      setTimeout(() => {
        accordionRef.current?.scrollIntoView({ 
          behavior: 'smooth',
          block: 'start'
        });
      }, 100);
    }
  }, [questions.length]);

  const reorderQuestion = (index, direction) => {
    setMovingIndex(index);
    setTimeout(() => {
      const newQuestions = [...questions];
      const [movedItem] = newQuestions.splice(index, 1);
      newQuestions.splice(index + direction, 0, movedItem);
      updateInterviewQuestions(newQuestions);
      setMovingIndex(null);
    }, 200);
  };

  const handleDelete = (id) => {
    const newQuestions = questions.filter(q => q.id !== id);
    updateInterviewQuestions(newQuestions);
  };

  const handleAdd = () => {
    const newQuestion = {
      id: `question_${Date.now()}_${Math.random()}`,
      text: '',
      category: 'professional_experience',
      description: ''
    };
    updateInterviewQuestions([...questions, newQuestion]);
  };

  const handleReloadSuggestions = async (customPrompt = '', type = '') => {
    setIsLoading(true);
    try {
      const suggestions = await fetchQuickAddsInterviewQuestions(
        formData, 
        false, 
        customPrompt,
        type || undefined
      );
      setQuickAddQuestions(suggestions);
    } catch (error) {
      console.error('Failed to reload suggestions', error);
    } finally {
      setIsLoading(false);
    }
  };

  const addQuestionFromQuickAdd = (question) => {
    updateInterviewQuestions([...questions, question]);
    setQuickAddQuestions(prev => prev.filter(q => q.id !== question.id));
  };

  const handleGenerateScheme = async () => {
    setIsGenerating(true);
    try {
      const generatedQuestions = await generateInterviewScheme(formData);
      updateInterviewQuestions([...questions, ...generatedQuestions]);
      setIsExpanded(true);
    } finally {
      setIsGenerating(false);
    }
  };

  const handleQuestionChange = (index, field, value) => {
    const newQuestions = [...questions];
    newQuestions[index] = {
      ...newQuestions[index],
      [field]: value
    };
    updateInterviewQuestions(newQuestions);
  };

  return (
    <Card className="max-w-5xl mx-auto">
      <CardHeader 
        data={{ questions }}
        requiredFields={['questions']}
        isExpanded={isExpanded}
        onClick={() => setIsExpanded(!isExpanded)}
        alwaysShowInput={true}
        inputSection={
          <div className="flex items-center gap-2">
            {questions.length > 0 && (
              <>
                <div className="flex items-center gap-2" onClick={e => e.stopPropagation()}>
                  <DownloadButton
                    label="Interview Guide"
                    onPDFClick={() => generateInterviewPDF(questions, formData)}
                    onDOCXClick={() => generateInterviewDOCX(questions, formData)}
                  />

                  <DownloadButton
                    label="Evaluation Form"
                    onPDFClick={() => generateEvaluationPDF(questions, formData)}
                    onDOCXClick={() => generateEvaluationSchemeDOCX(questions, formData)}
                    onXLSXClick={() => generateComparisonMatrix(questions, formData)}
                  />
                </div>
              </>
            )}

            <GradientButton
              onClick={(e) => {
                e.stopPropagation();
                handleGenerateScheme();
              }}
              disabled={isGenerating}
              icon={Sparkles}
              loadingText="Generating"
              isLoading={isGenerating}
              className="!bg-gradient-to-r !from-blue-600 !to-blue-500 hover:!from-blue-700 hover:!to-blue-600 !px-3"
            >
              Generate
            </GradientButton>
          </div>
        }
      >
        <div className="flex items-center gap-2">
          <HelpCircle className="h-5 w-5" />
          <div>
            <CardTitle>Interview Questions</CardTitle>
            <CardSubHeader 
              count={questions.length || undefined}
              emptyText="Generate role-specific interview questions"
            >
              questions generated
            </CardSubHeader>
          </div>
        </div>
      </CardHeader>

      {isExpanded && (
        <CardContent className="space-y-6">
          <div className={`grid ${isQuickAddCollapsed ? 'grid-cols-1' : 'grid-cols-3'} gap-6`}>
            <div className={`${isQuickAddCollapsed ? 'col-span-1' : 'col-span-2'} space-y-3`}>
              {questions.map((question, index) => (
                <QuestionEditor
                  key={question.id}
                  question={question}
                  index={index}
                  totalQuestions={questions.length}
                  onTextChange={(value) => handleQuestionChange(index, 'text', value)}
                  onTypeChange={(value) => handleQuestionChange(index, 'type', value)}
                  onDescriptionChange={(value) => handleQuestionChange(index, 'description', value)}
                  onReorder={(direction) => reorderQuestion(index, direction)}
                  onDelete={() => handleDelete(question.id)}
                  movingIndex={movingIndex}
                  activeTooltip={activeTooltip}
                  setActiveTooltip={setActiveTooltip}
                />
              ))}
              <button
                onClick={handleAdd}
                className="mt-4 flex items-center gap-2 text-blue-600 hover:text-blue-800"
              >
                <Plus className="h-4 w-4" />
                Add Question
              </button>
            </div>
            <div className={`${isQuickAddCollapsed ? 'hidden' : 'block'}`}>
              <QuickAddPanel
                questions={quickAddQuestions}
                isLoading={isLoading}
                onQuickAdd={addQuestionFromQuickAdd}
                onReload={handleReloadSuggestions}
              />
            </div>
          </div>
        </CardContent>
      )}
    </Card>
  );
};

export default InterviewQuestionsAccordion; 