import React from 'react';
import { ToasterComponent } from '../components/common/ToastManager';
import RoleViewer from '../components/RoleViewer';

function RoleViewerPage() {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-5xl mx-auto">
        <ToasterComponent />
        <RoleViewer />
      </div>
    </div>
  );
}

export default RoleViewerPage; 