export async function fetchFromChatGPT(payload) {
  const GPT_WORKER_URL = 'https://predicthire-free-fetch.philip-d02.workers.dev';

  try {
    // Log the payload before sending it
    console.log('Payload sent to GPT Worker:', JSON.stringify(payload, null, 2));

    const response = await fetch(GPT_WORKER_URL, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        endpoint: 'https://api.openai.com/v1/chat/completions',
        payload,
      }),
    });

    if (!response.ok) {
      const errorText = await response.text();
      throw new Error(`GPT Worker Error: ${errorText}`);
    }

    // Parse and log the response
    const data = await response.json();
    console.log('Raw GPT Worker Response:', JSON.stringify(data, null, 2));

    return data;
  } catch (error) {
    console.error('Error fetching from GPT Worker:', error);
    throw error;
  }
}
