import React from 'react';
import { Switch } from '@headlessui/react';
import { Sparkles } from 'lucide-react';

const ModeToggle = ({ isProMode, setIsProMode }) => {
  const features = {
    basic: [
      "Interview Questions Generator",
      "Basic Job Description",
      "Quick Export Options"
    ],
    pro: [
      "Complete Recruitment Journey",
      "Professional Job Posts",
      "Candidate Personae Creation",
      "Onboarding Plan Builder",
      "Timeline & Process Planning",
      "Core Responsibilities Design",
      "Success Outcomes Framework"
    ]
  };

  return (
    <div className="relative">
      <Switch.Group>
        <div className="flex items-center gap-3">
          <Switch.Label className="text-sm font-medium text-gray-700">
            {isProMode ? (
              <span className="flex items-center gap-1">
                <Sparkles className="h-4 w-4 text-blue-500" />
                Pro Mode
              </span>
            ) : (
              'Basic Mode'
            )}
          </Switch.Label>
          <Switch
            checked={isProMode}
            onChange={setIsProMode}
            className={`${
              isProMode ? 'bg-blue-600' : 'bg-gray-300'
            } relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none`}
          >
            <span
              className={`${
                isProMode ? 'translate-x-6' : 'translate-x-1'
              } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
            />
          </Switch>
        </div>
      </Switch.Group>

      {/* Feature tooltip */}
      <div className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-lg border border-gray-200 p-4 z-50 hidden group-hover:block">
        <div className="space-y-4">
          <div>
            <h4 className="font-medium text-gray-900 mb-2">Basic Features:</h4>
            <ul className="text-sm text-gray-600 space-y-1">
              {features.basic.map((feature, index) => (
                <li key={index} className="flex items-center gap-2">
                  <span className="w-1.5 h-1.5 bg-gray-400 rounded-full" />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h4 className="font-medium text-gray-900 mb-2">Pro Features:</h4>
            <ul className="text-sm text-gray-600 space-y-1">
              {features.pro.map((feature, index) => (
                <li key={index} className="flex items-center gap-2">
                  <span className="w-1.5 h-1.5 bg-blue-400 rounded-full" />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModeToggle; 