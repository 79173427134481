import { Briefcase, Wrench, GraduationCap, Award, TrendingUp, Users, Smile, HelpCircle } from 'lucide-react';

export const FACTOR_CATEGORIES = {
  technical: {
    id: 'technical',
    label: 'Skills',
    description: 'Focus on whether the candidate has the technical ability to perform the role',
    factors: ['professional_experience', 'skills', 'education'],
    bgColor: 'bg-[#fff5e6]',
    textColor: 'text-[#faa00c]',
    tooltipColor: 'bg-[#faa00c]'
  },
  performance: {
    id: 'performance',
    label: 'Performance',
    description: 'Evaluate how well the candidate handles challenges and demonstrates growth',
    factors: ['achievements', 'progression', 'problem_solving'],
    bgColor: 'bg-[#f3e6f5]',
    textColor: 'text-[#87328f]',
    tooltipColor: 'bg-[#87328f]'
  },
  cultural: {
    id: 'cultural',
    label: 'Culture',
    description: 'Assess alignment with team/company culture and soft skills',
    factors: ['cultural_fit', 'attitude'],
    bgColor: 'bg-[#f2fae6]',
    textColor: 'text-[#84bd00]',
    tooltipColor: 'bg-[#84bd00]'
  }
};

export const factors = [
  {
    id: 'professional_experience',
    icon: Briefcase,
    label: 'Professional Experience',
    category: 'technical',
    bgColor: 'bg-[#fff5e6]',
    iconColor: 'stroke-[#faa00c]',
    tooltipColor: 'bg-[#faa00c]',
    description: 'Past work experience and roles'
  },
  {
    id: 'skills',
    icon: Wrench,
    label: 'Skills and Qualifications',
    category: 'technical',
    bgColor: 'bg-[#ffebf3]',
    iconColor: 'stroke-[#ee1065]',
    tooltipColor: 'bg-[#ee1065]',
    description: 'Technical abilities and certifications'
  },
  {
    id: 'education',
    icon: GraduationCap,
    label: 'Educational Background',
    category: 'technical',
    bgColor: 'bg-[#e6f6fe]',
    iconColor: 'stroke-[#27aae1]',
    tooltipColor: 'bg-[#27aae1]',
    description: 'Academic qualifications and training'
  },
  {
    id: 'achievements',
    icon: Award,
    label: 'Achievements and Accomplishments',
    category: 'performance',
    bgColor: 'bg-[#f3e6f5]',
    iconColor: 'stroke-[#87328f]',
    tooltipColor: 'bg-[#87328f]',
    description: 'Key accomplishments and successes'
  },
  {
    id: 'progression',
    icon: TrendingUp,
    label: 'Career Progression',
    category: 'performance',
    bgColor: 'bg-[#e6fafa]',
    iconColor: 'stroke-[#00a9ac]',
    tooltipColor: 'bg-[#00a9ac]',
    description: 'Growth and advancement over time'
  },
  {
    id: 'problem_solving',
    icon: HelpCircle,
    label: 'Problem-Solving Ability',
    category: 'performance',
    bgColor: 'bg-[#f0f0f0]',
    iconColor: 'stroke-[#000000]',
    tooltipColor: 'bg-[#000000]',
    description: 'Problem-solving and analytical abilities'
  },
  {
    id: 'cultural_fit',
    icon: Users,
    label: 'Cultural Fit',
    category: 'cultural',
    bgColor: 'bg-[#f2fae6]',
    iconColor: 'stroke-[#84bd00]',
    tooltipColor: 'bg-[#84bd00]',
    description: 'Alignment with company values and culture'
  },
  {
    id: 'attitude',
    icon: Smile,
    label: 'Attitude',
    category: 'cultural',
    bgColor: 'bg-[#fff0eb]',
    iconColor: 'stroke-[#ff4500]',
    tooltipColor: 'bg-[#ff4500]',
    description: 'Personality traits and work approach'
  }
]; 