import React from 'react';
import toast, { Toaster } from 'react-hot-toast';
import { CheckCircle2, XCircle, AlertCircle, Info } from 'lucide-react';  // Import Lucide icons

// Toast types
export const TOAST_TYPES = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
};

// Custom icons for each toast type
const ToastIcon = ({ type }) => {
  switch (type) {
    case TOAST_TYPES.SUCCESS:
      return <CheckCircle2 className="text-white" size={18} />;
    case TOAST_TYPES.ERROR:
      return <XCircle className="text-white" size={18} />;
    case TOAST_TYPES.WARNING:
      return <AlertCircle className="text-white" size={18} />;
    case TOAST_TYPES.INFO:
      return <Info className="text-white" size={18} />;
    default:
      return null;
  }
};

// Toast configurations
export const ToasterComponent = () => (
  <Toaster 
    position="bottom-left"
    reverseOrder={false}
    gutter={8}
    containerStyle={{
      bottom: 20,
      left: 20,
    }}
    toastOptions={{
      duration: 5000,
      style: {
        background: '#333',
        color: '#fff',
        padding: '16px',
        borderRadius: '8px',
        maxWidth: '500px',
        fontSize: '14px',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
      },
    }}
  />
);

// Toast styles based on type
const TOAST_STYLES = {
  [TOAST_TYPES.SUCCESS]: {
    background: '#10B981', // Green
    color: 'white',
  },
  [TOAST_TYPES.ERROR]: {
    background: '#EF4444', // Red
    color: 'white',
  },
  [TOAST_TYPES.INFO]: {
    background: '#3B82F6', // Blue
    color: 'white',
  },
  [TOAST_TYPES.WARNING]: {
    background: '#F59E0B', // Yellow
    color: 'white',
  },
};

// Non-blocking toast function with sequential timing
let lastToastTime = 0;

export const showToast = (message, type = TOAST_TYPES.INFO) => {
  const now = Date.now();
  const delay = Math.max(0, lastToastTime + 100 - now);

  setTimeout(() => {
    const style = {
      ...TOAST_STYLES[type],
      borderRadius: '8px',
      padding: '12px 24px',
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
    };

    const icon = <ToastIcon type={type} />;

    switch (type) {
      case TOAST_TYPES.SUCCESS:
        toast.custom(
          <div style={style}>
            {icon}
            {message}
          </div>
        );
        break;
      case TOAST_TYPES.ERROR:
        toast.custom(
          <div style={style}>
            {icon}
            {message}
          </div>
        );
        break;
      case TOAST_TYPES.WARNING:
        toast.custom(
          <div style={style}>
            {icon}
            {message}
          </div>
        );
        break;
      default:
        toast.custom(
          <div style={style}>
            {icon}
            {message}
          </div>
        );
    }
    
    lastToastTime = Date.now();
  }, delay);
};

// Usage example in any component:
// import { showToast, TOAST_TYPES } from '../components/ToastManager';
// showToast('Operation successful!', TOAST_TYPES.SUCCESS);
// showToast('Something went wrong', TOAST_TYPES.ERROR);
// showToast('Please wait...', TOAST_TYPES.INFO); 