/**
 * Basic date calculations
 */
export const calculateDaysDifference = (date1, date2) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  return Math.floor((d2 - d1) / (1000 * 60 * 60 * 24));
};

/**
 * Date formatting
 */
export const formatDate = (date) => {
  if (!date) return '';
  return new Date(date).toLocaleDateString('en-US', {
    weekday: 'short',
    month: 'short',
    day: 'numeric'
  });
};

export const calculateDaysFromNow = (date) => {
  if (!date) return '';
  const today = new Date();
  today.setHours(0, 0, 0, 0);
  const targetDate = new Date(date);
  targetDate.setHours(0, 0, 0, 0);
  
  const days = Math.floor((targetDate - today) / (1000 * 60 * 60 * 24));
  
  if (days === 0) return 'Today';
  if (days === 1) return 'Tomorrow';
  if (days === -1) return 'Yesterday';
  if (days < 0) return `${Math.abs(days)} days ago`;
  return `in ${days} days`;
};

/**
 * Timeline generation and adjustments
 */
export const createInitialTimeline = (startDate, targetDate) => {
  const roleDefinitionDate = new Date(startDate);
  const totalDays = calculateDaysDifference(roleDefinitionDate, new Date(targetDate));
  
  // Place screening at 30% of the time between today and target date
  const screeningDeadline = new Date(roleDefinitionDate);
  screeningDeadline.setDate(roleDefinitionDate.getDate() + Math.floor(totalDays * 0.3));
  
  // Place assessment at 60% of the time between today and target date
  const assessmentDeadline = new Date(roleDefinitionDate);
  assessmentDeadline.setDate(roleDefinitionDate.getDate() + Math.floor(totalDays * 0.6));
  
  const steps = [
    {
      id: Date.now(),
      text: 'Initial Role Definition',
      date: startDate
    },
    {
      id: Date.now() + 1,
      text: 'PredictHire CV & Video Screening Deadline',
      date: screeningDeadline.toISOString().split('T')[0]
    },
    {
      id: Date.now() + 2,
      text: 'PredictHire Candidate Assessment Deadline',
      date: assessmentDeadline.toISOString().split('T')[0]
    },
    {
      id: Date.now() + 3,
      text: 'Target Candidate Start Date',
      date: targetDate
    }
  ];

  return steps.sort((a, b) => new Date(a.date) - new Date(b.date));
};

export const calculateAdjustedDates = (steps, oldTargetDate, newTargetDate) => {
  const initialStep = steps.find(step => step.text === 'Initial Role Definition');
  if (!initialStep) return steps;

  const startDate = new Date(initialStep.date);
  const oldEnd = new Date(oldTargetDate);
  const newEnd = new Date(newTargetDate);
  
  // Calculate total durations
  const oldDuration = calculateDaysDifference(startDate, oldEnd);
  const newDuration = calculateDaysDifference(startDate, newEnd);
  
  // If either duration is 0, maintain original dates
  if (oldDuration === 0 || newDuration === 0) return steps;

  const newSteps = steps.map(step => {
    // Keep Initial Role Definition date unchanged
    if (step.text === 'Initial Role Definition') return step;
    
    // Set Target Candidate Start Date to the new target date
    if (step.text === 'Target Candidate Start Date') {
      return { ...step, date: newTargetDate };
    }

    // For all other steps, calculate their relative position and new date
    const currentDate = new Date(step.date);
    const daysFromStart = calculateDaysDifference(startDate, currentDate);
    
    // Calculate the percentage through the timeline (0 to 1)
    const percentComplete = daysFromStart / oldDuration;
    
    // Apply that same percentage to the new duration
    const newDaysFromStart = Math.round(newDuration * percentComplete);
    
    // Calculate the new date
    const newDate = new Date(startDate);
    newDate.setDate(startDate.getDate() + newDaysFromStart);

    return {
      ...step,
      date: newDate.toISOString().split('T')[0]
    };
  });

  // Sort steps by date
  return [...newSteps].sort((a, b) => new Date(a.date) - new Date(b.date));
}; 