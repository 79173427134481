import { Document, Paragraph, TextRun, HeadingLevel, Packer } from 'docx';

const parseMarkdown = (text) => {
  // Split into lines for processing
  const lines = text.split('\n');
  const elements = [];

  let currentList = [];
  let inList = false;

  lines.forEach(line => {
    const trimmedLine = line.trim();

    // Handle headings
    if (trimmedLine.startsWith('# ')) {
      if (inList) {
        elements.push(createList(currentList));
        currentList = [];
        inList = false;
      }
      elements.push(new Paragraph({
        text: trimmedLine.substring(2),
        heading: HeadingLevel.HEADING_1,
        spacing: { before: 400, after: 200 }
      }));
    }
    // Handle subheadings
    else if (trimmedLine.startsWith('## ')) {
      if (inList) {
        elements.push(createList(currentList));
        currentList = [];
        inList = false;
      }
      elements.push(new Paragraph({
        text: trimmedLine.substring(3),
        heading: HeadingLevel.HEADING_2,
        spacing: { before: 300, after: 200 }
      }));
    }
    // Handle bullet points
    else if (trimmedLine.startsWith('- ')) {
      inList = true;
      currentList.push({
        text: trimmedLine.substring(2),
        type: 'bullet'
      });
    }
    // Handle numbered lists
    else if (/^\d+\.\s/.test(trimmedLine)) {
      inList = true;
      currentList.push({
        text: trimmedLine.substring(trimmedLine.indexOf(' ') + 1),
        type: 'number'
      });
    }
    // Handle regular paragraphs
    else if (trimmedLine) {
      if (inList) {
        elements.push(createList(currentList));
        currentList = [];
        inList = false;
      }
      elements.push(new Paragraph({
        children: parseInlineFormatting(trimmedLine),
        spacing: { before: 200, after: 200 }
      }));
    }
    // Handle empty lines
    else if (!trimmedLine && inList) {
      elements.push(createList(currentList));
      currentList = [];
      inList = false;
    }
  });

  // Handle any remaining list items
  if (currentList.length > 0) {
    elements.push(createList(currentList));
  }

  return elements;
};

const parseInlineFormatting = (text) => {
  const parts = [];
  let currentText = '';
  let isBold = false;
  let isItalic = false;

  for (let i = 0; i < text.length; i++) {
    if (text.substring(i, i + 2) === '**') {
      if (currentText) {
        parts.push(new TextRun({
          text: currentText,
          bold: isBold,
          italic: isItalic
        }));
        currentText = '';
      }
      isBold = !isBold;
      i++;
    }
    else if (text[i] === '_') {
      if (currentText) {
        parts.push(new TextRun({
          text: currentText,
          bold: isBold,
          italic: isItalic
        }));
        currentText = '';
      }
      isItalic = !isItalic;
    }
    else {
      currentText += text[i];
    }
  }

  if (currentText) {
    parts.push(new TextRun({
      text: currentText,
      bold: isBold,
      italic: isItalic
    }));
  }

  return parts;
};

const createList = (items) => {
  return new Paragraph({
    bullet: items[0].type === 'bullet',
    numbering: items[0].type === 'number' ? {
      reference: 'default-numbering',
      level: 0
    } : undefined,
    children: parseInlineFormatting(items[0].text),
    spacing: { before: 100, after: 100 }
  });
};

export const generateJobPostDOCX = async (jobPost, formData) => {
  try {
    // Create sanitized filename
    const jobTitle = (formData?.roleData?.jobTitle || 'role').replace(/[^a-z0-9]/gi, '-').toLowerCase();
    const companyName = (formData?.companyData?.name || 'company').replace(/[^a-z0-9]/gi, '-').toLowerCase();
    const fileName = `job-post-${companyName}-${jobTitle}.docx`;

    // Create document with parsed markdown
    const doc = new Document({
      sections: [{
        properties: {},
        children: parseMarkdown(jobPost)
      }]
    });

    // Generate and download the document
    const blob = await Packer.toBlob(doc);
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error generating DOCX:', error);
    throw error;
  }
}; 