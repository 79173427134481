import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { pushToDataLayer, GTMEvents } from './utils/gtm';

const root = ReactDOM.createRoot(document.getElementById('root'));

// Send initial page view event
pushToDataLayer(GTMEvents.PAGE_VIEW(
  document.title,
  window.location.pathname
));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// Track core web vitals
reportWebVitals((metric) => {
  if (metric.name === 'FCP' || metric.name === 'LCP' || metric.name === 'CLS') {
    pushToDataLayer({
      event: 'web_vitals',
      metric_name: metric.name,
      metric_value: Math.round(metric.value * 100) / 100,
    });
  }
});
