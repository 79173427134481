export const normalizeText = (text, acceptablePhrases = []) => {
  if (!text) return '';
  
  let normalizedText = text.toLowerCase();
  acceptablePhrases.forEach(phrase => {
    normalizedText = normalizedText.replace(phrase, '');
  });
  
  return normalizedText;
};

export const createValidationTip = (type, tips) => {
  if (!type) return {
    title: '',
    tip: '',
    example: ''
  };

  return tips[type] || {
    title: "Warning",
    tip: "This content might need revision. Please review the highlighted concerns.",
    example: "Consider rephrasing to ensure compliance with interview best practices."
  };
}; 