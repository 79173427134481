import React, { useState, useRef, useEffect } from 'react';
import { Download, ChevronDown, FileSpreadsheet, FileType2, FileText } from 'lucide-react';

const DownloadButton = ({ 
  label, 
  onPDFClick, 
  onDOCXClick,
  onXLSXClick,
  pdfClassName = "text-red-700 hover:bg-red-50 border-red-200",
  docxClassName = "text-blue-700 hover:bg-blue-50 border-blue-200",
  xlsxClassName = "text-green-700 hover:bg-green-50 border-green-200"
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleMainButtonClick = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (e, callback) => {
    e.stopPropagation();
    callback(e);
    setIsOpen(false);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <button
        onClick={handleMainButtonClick}
        className="flex items-center gap-1.5 px-3 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-md transition-colors border border-gray-200"
      >
        <Download className="h-4 w-4" />
        {label}
        <ChevronDown className={`h-4 w-4 transition-transform ${isOpen ? 'rotate-180' : ''}`} />
      </button>

      {isOpen && (
        <div className="absolute right-0 mt-1 py-1 bg-white rounded-lg shadow-lg border z-50 min-w-[120px]">
          <button
            onClick={(e) => handleOptionClick(e, onPDFClick)}
            className={`w-full flex items-center gap-2 px-3 py-2 text-sm hover:bg-gray-50 ${pdfClassName}`}
          >
            <FileType2 className="h-4 w-4" />
            PDF
          </button>
          <button
            onClick={(e) => handleOptionClick(e, onDOCXClick)}
            className={`w-full flex items-center gap-2 px-3 py-2 text-sm hover:bg-gray-50 ${docxClassName}`}
          >
            <FileText className="h-4 w-4" />
            DOCX
          </button>
          {onXLSXClick && (
            <button
              onClick={(e) => handleOptionClick(e, onXLSXClick)}
              className={`w-full flex items-center gap-2 px-3 py-2 text-sm hover:bg-gray-50 ${xlsxClassName}`}
            >
              <FileSpreadsheet className="h-4 w-4" />
              Comparison Matrix
            </button>
          )}
        </div>
      )}
    </div>
  );
};

export default DownloadButton; 