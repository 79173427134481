import { getTypeInfo } from './questionFormatters';
import { normalizeText, createValidationTip } from './validationUtils';

export const getWordCount = (text) => {
  return text.trim().split(/\s+/).length;
};

export const isQuestionTooLong = (text, type) => {
  const typeInfo = getTypeInfo(type);
  const wordCount = getWordCount(text);
  return wordCount > typeInfo.max;
};

export const isQuestionComplex = (text) => {
  if (!text || text.trim().length < 5) return false;

  const acceptablePhrases = [
    'trends and insights',
    'roles and responsibilities',
    'goals and objectives',
    'developing and executing',
    'planning and implementing',
    'strategy and execution',
    'process and methodology'
  ];

  const normalizedText = normalizeText(text, acceptablePhrases);

  // Only flag obvious cases:
  const questionMarks = (text.match(/\?/g) || []).length;
  if (questionMarks > 1) return 'multipleQuestions';

  const hasUnrelatedTopics = /marketing.*and.*programming|technical.*and.*sales|design.*and.*finance/i.test(normalizedText);
  if (hasUnrelatedTopics) return 'unrelatedTopics';

  return false;
};

export const getQuestionComplexityTip = (text) => {
  const complexityType = isQuestionComplex(text);
  
  const tips = {
    multipleQuestions: {
      title: "Multiple Questions Combined",
      tip: "This question contains multiple separate questions. Consider asking them one at a time to get more detailed responses.",
      example: "Try splitting this into separate questions to allow the candidate to fully address each point."
    },
    unrelatedTopics: {
      title: "Mixed Topics",
      tip: "This question covers distinctly different topics. Consider focusing on one area at a time.",
      example: "Asking about one topic at a time helps candidates provide more focused and relevant responses."
    }
  };

  return createValidationTip(complexityType, tips);
}; 