import toast from 'react-hot-toast';
import { AlertCircle } from 'lucide-react';

const normalizeUrl = (url) => {
  if (!url) return '';
  
  // Add https:// if no protocol is specified
  if (!url.startsWith('http://') && !url.startsWith('https://')) {
    return `https://${url}`;
  }
  return url;
};

export const fetchCompanyProfile = async (url) => {
  try {
    const normalizedUrl = normalizeUrl(url);
    console.log('Fetching profile for:', normalizedUrl);

    // 1. First get the HTML content using the scraper
    const scraperResponse = await fetch('https://predicthirescraper.philip-d02.workers.dev/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({ 
        url: normalizedUrl,
        waitUntil: 'networkidle0'
      })
    });

    const responseText = await scraperResponse.text();
    console.log('Scraper status:', scraperResponse.status);

    if (!scraperResponse.ok) {
      toast.error((t) => (
        <div className="flex items-center gap-2">
          <AlertCircle className="h-5 w-5" />
          <div className="flex flex-col">
            <span className="font-medium">Scraping Failed</span>
            <span className="text-sm">Could not access the website</span>
          </div>
        </div>
      ), { duration: 5000 });
      throw new Error(`Scraper failed: ${responseText}`);
    }

    let scraperData;
    try {
      scraperData = JSON.parse(responseText);
      
      // Add detailed logging
      console.log('Scraped content length:', scraperData.html.length);
      console.log('First 500 chars of scraped content:', scraperData.html.slice(0, 500));
      console.log('Last 500 chars of scraped content:', scraperData.html.slice(-500));
      
      // Rough token estimate (1 token ≈ 4 chars)
      console.log('Estimated tokens:', Math.ceil(scraperData.html.length / 4));
      
    } catch (e) {
      toast.error((t) => (
        <div className="flex items-center gap-2">
          <AlertCircle className="h-5 w-5" />
          <div className="flex flex-col">
            <span className="font-medium">Invalid Response</span>
            <span className="text-sm">Website returned unexpected data</span>
          </div>
        </div>
      ), { duration: 5000 });
      throw new Error('Invalid JSON from scraper');
    }
    
    if (!scraperData.html) {
      toast.error((t) => (
        <div className="flex items-center gap-2">
          <AlertCircle className="h-5 w-5" />
          <div className="flex flex-col">
            <span className="font-medium">No Content Found</span>
            <span className="text-sm">The page appears to be empty</span>
          </div>
        </div>
      ), { duration: 5000 });
      throw new Error('No HTML content received');
    }

    // 2. Then analyze the content with GPT
    const gptResponse = await fetch('https://predicthire-free-fetch.philip-d02.workers.dev/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        endpoint: 'https://api.openai.com/v1/chat/completions',
        payload: {
          model: 'gpt-3.5-turbo-1106',
          messages: [
            {
              role: 'system',
              content: `You are an expert at analyzing company information. Extract key details from the provided HTML content.
Focus on being specific and informative while avoiding generic statements.

Return your analysis in the following JSON format:
{
  "name": "Company Name Here",
  "description": "2-3 sentences describing the company's core business, target market, and unique value proposition.",
  "industry": "Specific industry category with sub-category if applicable",
  "culture": "3-4 specific points about company values, work environment, and employee experience. Include concrete examples when available."
}

Guidelines:
- Description should be 40-60 words, focused on what makes the company unique
- Industry should be specific (e.g. "Enterprise Cloud Security Software" not just "Technology")
- Culture should highlight distinctive values and practices with examples
- Avoid generic statements like "great place to work" or "innovative company"
- Include specific initiatives, programs, or practices that demonstrate company culture
- Avoid marketing language and focus on factual information

Analyze the provided HTML and respond ONLY with the JSON object.`
            },
            {
              role: 'user',
              content: scraperData.html
            }
          ],
          temperature: 0.1,
          max_tokens: 1000,
          response_format: { type: "json_object" }
        }
      })
    });

    if (!gptResponse.ok) {
      const errorText = await gptResponse.text();
      console.error('GPT Analysis failed:', gptResponse.status, errorText);
      toast.error((t) => (
        <div className="flex items-center gap-2">
          <AlertCircle className="h-5 w-5" />
          <div className="flex flex-col">
            <span className="font-medium">Analysis Failed</span>
            <span className="text-sm">Could not process company information</span>
          </div>
        </div>
      ), { duration: 5000 });
      throw new Error(`GPT analysis failed: ${errorText}`);
    }

    const gptData = await gptResponse.json();
    
    if (!gptData.choices?.[0]?.message?.content) {
      toast.error((t) => (
        <div className="flex items-center gap-2">
          <AlertCircle className="h-5 w-5" />
          <div className="flex flex-col">
            <span className="font-medium">Invalid Analysis</span>
            <span className="text-sm">Received unexpected response format</span>
          </div>
        </div>
      ), { duration: 5000 });
      throw new Error('Invalid response from GPT');
    }

    // Parse GPT's JSON response
    const analysis = JSON.parse(gptData.choices[0].message.content);

    return {
      name: analysis.name || '',
      description: analysis.description || '',
      industry: analysis.industry || '',
      culture: analysis.culture || '',
      aboutUrl: url
    };

  } catch (error) {
    console.error('Error in fetchCompanyProfile:', error);
    throw error;
  }
};