import React, { useState } from 'react';
import { Share2, Copy, Check, Loader } from 'lucide-react';
import { Card, CardHeader, CardTitle, CardContent, CardSubHeader } from '../CardComponents';
import { useFormContext } from '../../contexts/FormContext';
import { showToast, TOAST_TYPES } from '../common/ToastManager';
import { saveRole } from '../../services/roleStorage';
import ExportJsonRole from './export/ExportJsonRole';
import ExportDocxRole from './export/ExportDocxRole';
import { APP_BASENAME } from '../../App';

// Define production URL
const PRODUCTION_URL = 'https://predicthire.com';

const OutputsExport = () => {
  const { formData } = useFormContext();
  const [isExpanded, setIsExpanded] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const [shareUrl, setShareUrl] = useState(null);
  const [copied, setCopied] = useState(false);

  const handleShare = async () => {
    setIsSharing(true);
    try {
      console.log('[OutputsExport] Saving role with formData:', formData);
      const response = await saveRole(formData);
      console.log('[OutputsExport] Full response from saveRole:', response);
      
      const shareUrl = response?.url;
      console.log('[OutputsExport] Share URL:', shareUrl);
      
      if (!shareUrl) {
        console.error('[OutputsExport] No URL in response:', response);
        throw new Error('Failed to generate share URL');
      }
      
      setShareUrl(shareUrl);
      setIsExpanded(true);
      
      try {
        await navigator.clipboard.writeText(shareUrl);
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
        showToast('Role shared and link copied to clipboard! ✨', TOAST_TYPES.SUCCESS);
      } catch (copyError) {
        showToast('Role shared successfully! (Copy link manually)', TOAST_TYPES.SUCCESS);
      }
    } catch (error) {
      console.error('[OutputsExport] Error sharing role:', error);
      const errorMessage = error.message === 'Company name is required' || 
                         error.message === 'Job title is required' 
                         ? error.message 
                         : 'Failed to share role. Please try again.';
      showToast(errorMessage, TOAST_TYPES.ERROR);
    } finally {
      setIsSharing(false);
    }
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(shareUrl);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
      showToast('Link copied to clipboard!', TOAST_TYPES.SUCCESS);
    } catch (error) {
      showToast('Failed to copy link', TOAST_TYPES.ERROR);
    }
  };

  return (
    <Card className="max-w-5xl mx-auto mt-4">
      <CardHeader 
        data={formData}
        requiredFields={['companyData.name', 'roleData.jobTitle']}
        isExpanded={isExpanded}
        onClick={() => setIsExpanded(!isExpanded)}
        className="bg-purple-50 cursor-pointer"
        alwaysShowInput={true}
        inputSection={
          <button
            onClick={handleShare}
            disabled={isSharing}
            className="px-4 py-2 bg-[#7A1E8B] text-white rounded-md font-medium 
              hover:bg-[#671778] transition-colors duration-200 flex items-center gap-2"
          >
            {isSharing ? (
              <>
                <img 
                  src={`${APP_BASENAME}/loader.gif`} 
                  alt="Loading..." 
                  className="h-4 w-4" 
                />
                <span className="inline-flex items-center">
                  Sharing
                  <span className="ml-1 animate-pulse">...</span>
                </span>
              </>
            ) : (
              <>
                <Share2 className="h-4 w-4" />
                Share Role
              </>
            )}
          </button>
        }
      >
        <div className="flex items-center gap-2">
          <Share2 className="h-5 w-5 text-[#7A1E8B]" />
          <div>
            <CardTitle className="text-gray-900">✨ Export & Share Role Design ✨</CardTitle>
            <CardSubHeader className="text-gray-600">
              Export your role design or share it with your team
            </CardSubHeader>
          </div>
        </div>
      </CardHeader>

      {isExpanded && (
        <CardContent className="p-6">
          <div className="space-y-8">
            {shareUrl && (
              <div className="p-4 bg-purple-50 rounded-lg border border-purple-100">
                <p className="text-sm text-purple-700 mb-2">
                  ✨ Role saved! Use this link to share with your team:
                </p>
                <div className="flex items-center gap-2">
                  <input
                    type="text"
                    value={shareUrl}
                    readOnly
                    className="flex-1 p-2 bg-white border rounded text-sm"
                  />
                  <button
                    onClick={handleCopy}
                    className="px-3 py-2 bg-purple-100 hover:bg-purple-200 rounded text-purple-700 text-sm font-medium flex items-center gap-2"
                  >
                    {copied ? (
                      <>
                        <Check className="h-4 w-4" />
                        Copied
                      </>
                    ) : (
                      <>
                        <Copy className="h-4 w-4" />
                        Copy
                      </>
                    )}
                  </button>
                </div>
              </div>
            )}

            {/* Export options */}
            <div>
              <h3 className="text-sm font-medium text-gray-500 mb-4">Export Options</h3>
              <div className="grid grid-cols-2 gap-4">
                <ExportJsonRole />
                <ExportDocxRole />
              </div>
            </div>
          </div>
        </CardContent>
      )}
    </Card>
  );
};

export default OutputsExport; 