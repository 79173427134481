import React, { useState } from 'react';
import { Download, Loader } from 'lucide-react';
import { useFormContext } from '../../../contexts/FormContext';
import { showToast, TOAST_TYPES } from '../../common/ToastManager';
import { Document, Packer, Paragraph, HeadingLevel } from 'docx';

const ExportDocxRole = () => {
  const { formData } = useFormContext();
  const [isExporting, setIsExporting] = useState(false);

  const formatTimeframe = (timeframe) => {
    const timeframeMap = {
      'first_week': 'First Week',
      'first_month': 'First Month',
      'three_months': '3 Months',
      'six_months': '6 Months',
      'first_three_months': '3 Months',
      'first_six_months': '6 Months',
      'yearly': '1 Year'
    };
    return timeframeMap[timeframe] || timeframe;
  };

  const formatTimelines = (timelines) => {
    if (!timelines?.steps || timelines.steps.length === 0) {
      return ['No timeline entries yet'];
    }

    return timelines.steps
      .sort((a, b) => new Date(a.date) - new Date(b.date))
      .map((step, index) => {
        const date = step.date 
          ? new Date(step.date).toLocaleDateString()
          : 'Date not set';
        const duration = step.duration ? ` (${step.duration})` : '';
        return `${index + 1}. ${step.text}${duration} - ${date}`;
      });
  };

  const handleExportDocx = async () => {
    setIsExporting(true);
    try {
      const doc = new Document({
        sections: [{
          properties: {},
          children: [
            new Paragraph({
              text: "Role Design Document",
              heading: HeadingLevel.HEADING_1,
            }),
            new Paragraph({
              text: `Generated: ${new Date().toLocaleString()}`,
              spacing: { after: 400 }
            }),

            // Company Information
            new Paragraph({
              text: "COMPANY INFORMATION",
              heading: HeadingLevel.HEADING_2,
            }),
            new Paragraph({ text: `Company: ${formData.companyData?.name || 'N/A'}` }),
            new Paragraph({ text: `Industry: ${formData.companyData?.industry || 'N/A'}` }),
            new Paragraph({ text: `Description: ${formData.companyData?.description || 'N/A'}` }),
            new Paragraph({ 
              text: `Culture: ${formData.companyData?.culture || 'N/A'}`,
              spacing: { after: 400 }
            }),

            // Role Details
            new Paragraph({
              text: "ROLE DETAILS",
              heading: HeadingLevel.HEADING_2,
            }),
            new Paragraph({ text: `Job Title: ${formData.roleData?.jobTitle || 'N/A'}` }),
            new Paragraph({ text: `Department: ${formData.roleData?.department || 'N/A'}` }),
            new Paragraph({ text: `Reports To: ${formData.roleData?.reportsTo || 'N/A'}` }),
            new Paragraph({ text: `Work Model: ${formData.roleData?.workModel || 'N/A'}` }),
            new Paragraph({ text: `Employment Type: ${formData.roleData?.employmentType || 'N/A'}` }),
            new Paragraph({ 
              text: `Team Size: ${formData.roleData?.teamSize || 'N/A'}`,
              spacing: { after: 400 }
            }),

            // Responsibilities
            new Paragraph({
              text: "RESPONSIBILITIES",
              heading: HeadingLevel.HEADING_2,
            }),
            ...Object.values(formData.responsibilities || {})
              .filter(resp => resp && resp.text)
              .map((resp, index) => new Paragraph({ 
                text: `${index + 1}. ${resp.text}`,
                spacing: { after: 200 }
              })),

            // Key Outcomes
            new Paragraph({
              text: "KEY OUTCOMES",
              heading: HeadingLevel.HEADING_2,
            }),
            ...Object.values(formData.outcomes || {})
              .filter(outcome => outcome && outcome.text)
              .map((outcome, index) => new Paragraph({ 
                text: `${index + 1}. ${outcome.text}\nTimeframe: ${formatTimeframe(outcome.timeframe || 'Not specified')}`,
                spacing: { after: 200 }
              })),

            // Timeline
            new Paragraph({
              text: "TIMELINE",
              heading: HeadingLevel.HEADING_2,
            }),
            ...formatTimelines(formData.timelines).map(line => 
              new Paragraph({ 
                text: line,
                spacing: { after: 200 }
              })
            ),

            // Footer
            new Paragraph({
              text: "Generated with PredictHire.com",
              spacing: { before: 400 },
              alignment: "center"
            })
          ]
        }]
      });

      const blob = await Packer.toBlob(doc);
      const timestamp = new Date().toISOString().split('T')[0];
      const filename = `role-design_${timestamp}.docx`;

      const url = URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);

      showToast('Role data exported successfully!', TOAST_TYPES.SUCCESS);
    } catch (error) {
      console.error('Error exporting role data:', error);
      showToast('Failed to export role data', TOAST_TYPES.ERROR);
    } finally {
      setIsExporting(false);
    }
  };

  return (
    <button
      onClick={handleExportDocx}
      disabled={isExporting}
      className={`
        flex items-center gap-2 px-6 py-3 rounded-lg 
        ${isExporting 
          ? 'bg-gray-200 cursor-not-allowed' 
          : 'bg-blue-600 hover:bg-blue-700'} 
        text-white font-medium transition-colors
      `}
    >
      {isExporting ? (
        <>
          <Loader className="h-5 w-5 animate-spin" />
          Exporting...
        </>
      ) : (
        <>
          <Download className="h-5 w-5" />
          Download Role as DOCX
        </>
      )}
    </button>
  );
};

export default ExportDocxRole; 