import React, { useState } from 'react';
import { Pencil, Check, X } from 'lucide-react';

const QuestionDescription = ({ description, onChange, type }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editValue, setEditValue] = useState('');
  
  const handleEdit = () => {
    setEditValue(Array.isArray(description) ? description.join('\n') : description || '');
    setIsEditing(true);
  };

  const handleSave = () => {
    const newValue = editValue.trim();
    // Split by newlines and filter empty lines
    const descriptionArray = newValue.split('\n').filter(line => line.trim());
    onChange(descriptionArray.length === 1 ? descriptionArray[0] : descriptionArray);
    setIsEditing(false);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setEditValue('');
  };

  if (isEditing) {
    return (
      <div className="mt-2">
        <textarea
          value={editValue}
          onChange={(e) => setEditValue(e.target.value)}
          className="w-full min-h-[80px] p-2 text-sm border rounded-md focus:outline-none focus:ring-1 focus:ring-blue-500"
          placeholder={type === 'closing' ? 
            "Add topics you should be prepared to discuss with the candidate" : 
            "Enter evaluation points (one per line)"}
          autoFocus
        />
        <div className="flex justify-end gap-2 mt-2">
          <button
            onClick={handleCancel}
            className="p-1 text-gray-500 hover:text-gray-700 rounded-full hover:bg-gray-100"
          >
            <X className="h-4 w-4" />
          </button>
          <button
            onClick={handleSave}
            className="p-1 text-blue-600 hover:text-blue-800 rounded-full hover:bg-blue-50"
          >
            <Check className="h-4 w-4" />
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="text-sm text-gray-500 mt-2 group relative">
      {type === 'closing' && (
        <div className="text-xs text-blue-600 italic mb-1">
          Topics to prepare for candidate questions: Compensation and benefits, development opportunities, onboarding, culture and work environment.
        </div>
      )}
      <button
        onClick={handleEdit}
        className="absolute right-0 top-0 p-1 opacity-0 group-hover:opacity-100 transition-opacity text-gray-400 hover:text-gray-600 hover:bg-gray-100 rounded-full"
      >
        <Pencil className="h-3.5 w-3.5" />
      </button>
      {Array.isArray(description) ? (
        description.map((point, i) => (
          <div key={i} className="flex items-start mb-1 last:mb-0">
            <span className="text-gray-400 mr-2 leading-6">•</span>
            <span className="flex-1 leading-6">{point}</span>
          </div>
        ))
      ) : (
        <div className="flex items-start">
          <span className="text-gray-400 mr-2 leading-6">•</span>
          <span className="flex-1 leading-6">{description}</span>
        </div>
      )}
    </div>
  );
};

export default QuestionDescription; 