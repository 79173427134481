import React from 'react';
import OutputsExport from './OutputsExport';
import InterviewQuestionsAccordion from './interview/InterviewQuestionsAccordion';
import JobPostOutput from './jobpost/JobPostOutput';

const OutputsSection = () => {
  return (
    <div className="max-w-5xl mx-auto space-y-8">
      <OutputsExport />
      <InterviewQuestionsAccordion />
      <JobPostOutput />
    </div>
  );
};

export default OutputsSection; 