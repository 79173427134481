import { fetchFromChatGPT } from './fetchFromChatGPT';

const cleanGPTResponse = (content) => {
  if (typeof content !== 'string') return content;
  
  const cleaned = content
    .replace(/```json\n?/g, '')
    .replace(/```\n?/g, '')
    .trim();

  try {
    const parsed = JSON.parse(cleaned);
    return parsed.map(item => ({
      ...item,
      timeframe: new Date(item.timeframe).toISOString().split('T')[0]
    }));
  } catch (error) {
    console.warn('Failed to parse cleaned content, attempting to extract JSON array');
    const match = cleaned.match(/\[[\s\S]*\]/);
    if (match) {
      return JSON.parse(match[0]);
    }
    throw new Error('No valid JSON array found in response');
  }
};

const generateTimelinePrompt = (roleData, existingSteps) => {
  const today = new Date().toISOString().split('T')[0];
  const targetDate = new Date(roleData.targetDate);
  
  const existingDescriptions = existingSteps
    .map(step => step.text)
    .filter(text => text)
    .join('\n- ');

  return `Generate 3 additional timeline steps for a hiring process.
Target start date: ${roleData.targetDate}

Existing steps that should NOT be duplicated:
- ${existingDescriptions}

Please provide exactly 3 NEW timeline steps as a JSON array. Each step should:
- Be different from the existing steps
- Fall between today (${today}) and the target date (${roleData.targetDate})
- Include specific dates
- Focus on recruitment activities not already covered

Each step should include:
- "Suggestions": A unique step description
- "timeframe": The specific date (YYYY-MM-DD format)
- "duration": Approximate duration (e.g., "1 week", "2 days")

Return only the JSON array with exactly 3 items.`;
};

export const fetchQuickAddTimeline = async ({ targetDate, existingSteps }) => {
  const roleData = { targetDate };
  const prompt = generateTimelinePrompt(roleData, existingSteps);

  const payload = {
    model: 'gpt-3.5-turbo',
    messages: [
      { 
        role: 'system', 
        content: 'You are a helpful assistant generating unique hiring timeline steps. Return only a JSON array with exactly 3 items, without any markdown formatting.' 
      },
      { role: 'user', content: prompt },
    ],
    max_tokens: 300,
    temperature: 0.7,
  };

  try {
    const data = await fetchFromChatGPT(payload);
    const content = data.choices[0].message.content;
    const parsed = cleanGPTResponse(content);
    
    return Array.isArray(parsed) 
      ? parsed
          .filter((step) => step.Suggestions && step.timeframe)
          .slice(0, 3)
      : [];
  } catch (error) {
    console.error('Error fetching quick add suggestions:', error);
    return [];
  }
};
