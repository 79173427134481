import React from 'react';
import QuestionControls from './QuestionControls';
import QuestionReorderControls from './QuestionReorderControls';
import QuestionDescription from './QuestionDescription';
import { getPlaceholder } from '../utils/questionFormatters';
import { factors } from '../interviewFactorsList';

const QuestionEditor = ({
  question,
  index,
  totalQuestions,
  onTextChange,
  onDescriptionChange,
  onTypeChange,
  movingIndex,
  onReorder,
  onDelete,
  activeTooltip,
  setActiveTooltip
}) => {
  const factor = factors.find(f => f.id === question.type);

  return (
    <div className={`
      space-y-2 border rounded-lg p-4 group hover:border-gray-400 
      transition-all duration-200 relative
      ${movingIndex === index ? 'scale-102 shadow-md bg-blue-50' : ''}
    `}>
      <div className="flex flex-col gap-3">
        <textarea
          value={question.text}
          onChange={(e) => onTextChange(e.target.value)}
          className="w-full min-h-[80px] p-4 border border-gray-300 rounded-md text-xs leading-relaxed resize-none"
          placeholder={getPlaceholder(question.type)}
          rows={3}
        />

        <div className="flex items-center justify-between">
          <QuestionControls 
            question={question}
            type={question.type}
            onTypeChange={onTypeChange}
            activeTooltip={activeTooltip}
            setActiveTooltip={setActiveTooltip}
          />
          <QuestionReorderControls 
            index={index}
            totalQuestions={totalQuestions}
            onReorder={onReorder}
            onDelete={onDelete}
            movingIndex={movingIndex}
          />
        </div>

        <QuestionDescription 
          description={question.description}
          onChange={(newDescription) => onDescriptionChange(newDescription)}
          type={question.type === 'closing' ? 'closing' : undefined}
        />
      </div>
    </div>
  );
};

export default QuestionEditor; 