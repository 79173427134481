import React from 'react';
import { ArrowRight } from 'lucide-react';
import { getWordCount } from '../utils/questionValidation';
import { FACTOR_CATEGORIES, factors } from '../interviewFactorsList';
import { QUESTION_TYPES } from '../utils/questionTypeStyles';

const QuickAddItem = ({ question, onClick }) => {
  // Find the factor and its category
  const factor = factors.find(f => f.id === question.type);
  const category = FACTOR_CATEGORIES[factor?.category];

  return (
    <button
      onClick={onClick}
      className="w-full flex items-center gap-2 p-2 bg-white rounded border border-gray-200 text-xs hover:border-blue-500 hover:bg-blue-50 transition-colors"
    >
      <ArrowRight className="h-3 w-3 text-gray-400 flex-shrink-0" />
      <div className="flex-1 text-left">
        <div className="flex items-center gap-2 mb-1">
          {category && (
            <span className={`text-xs px-1.5 py-0.5 rounded ${QUESTION_TYPES[category.id]?.bg} ${QUESTION_TYPES[category.id]?.text}`}>
              {category.label}
            </span>
          )}
          <span className="text-xs text-gray-400">
            {getWordCount(question.text)} words
          </span>
        </div>
        <div className="text-gray-600">{question.text}</div>
      </div>
    </button>
  );
};

export default QuickAddItem; 