import axios from 'axios';

// Get environment variables with fallbacks
const PROJECT_REF = process.env.REACT_APP_SUPABASE_PROJECT_REF || 'tempqswqraeemrlajxrs';
const API_KEY = process.env.REACT_APP_API_KEY;

// More detailed environment logging
console.log('[roleStorage] Environment variables:', {
  nodeEnv: process.env.NODE_ENV,
  hasReactAppPrefix: !!Object.keys(process.env).find(key => key.startsWith('REACT_APP_')),
  envKeys: Object.keys(process.env).filter(key => key.startsWith('REACT_APP_')),
  projectRef: PROJECT_REF,
  hasApiKey: !!API_KEY,
  functionUrl: `https://${PROJECT_REF}.functions.supabase.co/save-role`,
  // Add these for debugging
  apiKeyLength: API_KEY?.length || 0,
  apiKeyPrefix: API_KEY?.substring(0, 5) || 'none',
  allEnvKeys: Object.keys(process.env)
});

if (!API_KEY) {
  console.warn('[roleStorage] API key not found in environment variables. Requests will fail.');
}

const FUNCTION_URL = `https://${PROJECT_REF}.functions.supabase.co/save-role`;

// Create axios instance with default config
const api = axios.create({
  baseURL: FUNCTION_URL,
  headers: {
    'Content-Type': 'application/json',
    'x-client-info': 'role-designer-frontend',
    'x-api-key': API_KEY
  },
  timeout: 10000
});

// Log environment configuration
console.log('[roleStorage] Environment check:', {
  hasApiKey: !!API_KEY,
  hasProjectRef: !!PROJECT_REF,
  functionUrl: FUNCTION_URL,
  origin: window.location.origin
});

// Add request interceptor for debugging
api.interceptors.request.use(request => {
  if (!PROJECT_REF) {
    throw new Error('Supabase project reference is not configured');
  }
  
  if (!API_KEY) {
    throw new Error('API key is not configured. Check environment variables.');
  }
  
  console.log('[roleStorage] Request:', {
    method: request.method,
    url: request.url,
    baseURL: request.baseURL,
    headers: {
      ...request.headers,
      'x-api-key': '***' // Hide API key in logs
    },
    data: request.data
  });
  return request;
});

// Add response interceptor for debugging
api.interceptors.response.use(
  response => {
    console.log('[roleStorage] Response:', {
      status: response.status,
      headers: response.headers,
      data: response.data
    });
    return response;
  },
  error => {
    console.error('[roleStorage] Response Error:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status,
      headers: error.response?.headers,
      config: error.config
    });
    return Promise.reject(error);
  }
);

export const saveRole = async (formData) => {
  try {
    // Validate required fields
    if (!formData.companyData?.name?.trim()) {
      throw new Error('Company name is required');
    }
    if (!formData.roleData?.jobTitle?.trim()) {
      throw new Error('Job title is required');
    }

    // Send data to Edge Function and return response directly
    const { data } = await api.post('', { roleData: formData });
    console.log('[roleStorage] Save response:', data);
    return data;  // Return the entire response
  } catch (error) {
    console.error('[roleStorage] Error saving role:', error.response?.data || error.message);
    throw error;
  }
};

export const getRole = async (permalink) => {
  try {
    console.log('[roleStorage] Starting getRole:', {
      permalink,
      baseURL: FUNCTION_URL,
      fullURL: `${FUNCTION_URL}/${permalink}`,
      headers: api.defaults.headers
    });
    
    const { data } = await api.get(`/${permalink}`);
    console.log('[roleStorage] Success:', data);
    return data;
  } catch (error) {
    console.error('[roleStorage] Error:', {
      message: error.message,
      response: error.response?.data,
      status: error.response?.status,
      config: {
        url: error.config?.url,
        baseURL: error.config?.baseURL,
        headers: error.config?.headers
      }
    });
    throw error;
  }
}; 