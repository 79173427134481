import { fetchFromChatGPT } from './fetchFromChatGPT';
import { FACTOR_CATEGORIES } from '../components/outputs/interview/interviewFactorsList';

const cleanJsonResponse = (content) => {
  return content.replace(/```json\n?|\n?```/g, '');
};

export const generateInterviewScheme = async (formData) => {
  const context = `
Company Information:
- Company Name: ${formData.companyData?.name || 'N/A'}
- Industry: ${formData.companyData?.industry || 'N/A'}
- Company Size: ${formData.companyData?.size || 'N/A'}

Role Details:
- Job Title: ${formData.roleData?.jobTitle || 'N/A'}
- Department: ${formData.roleData?.department || 'N/A'}
- Level: ${formData.roleData?.level || 'N/A'}
- Reports To: ${formData.roleData?.reportsTo || 'N/A'}
${formData.roleData?.teamSize ? `- Team Size: ${formData.roleData.teamSize}` : ''}

Key Responsibilities:
${formData.responsibilities
  ?.filter(resp => resp && resp.text)
  .map(resp => `- ${resp.text}`)
  .join('\n') || 'N/A'}

Key Outcomes:
${formData.outcomes
  ?.filter(outcome => outcome && outcome.text)
  .map(outcome => `- ${outcome.text}`)
  .join('\n') || 'N/A'}

${formData.timelines?.steps?.length ? `
Role Timeline:
${formData.timelines.steps
  .map(step => `- ${step.duration}: ${step.description}`)
  .join('\n')}
` : ''}

${formData.outputs?.requirements ? `
Additional Requirements:
${formData.outputs.requirements}
` : ''}
`;

  try {
    const payload = {
      model: "gpt-3.5-turbo",
      messages: [
        {
          role: "system",
          content: `You are an expert HR professional. Generate a structured interview scheme with 7-9 questions total, following this pattern:

1. Opening (1-2 cultural questions):
   - Start with engaging, rapport-building questions that relate to the company's industry and culture
   - Use factors: cultural_fit, attitude
   - Example: "What interests you about working in [industry] at [company]?"

2. Skills Assessment (2-3 questions):
   - Focus on the specific responsibilities and required skills
   - Use factors: professional_experience, skills, education
   - Base questions on the actual role requirements provided

3. Performance Evaluation (2-3 questions):
   - Align with key outcomes and timeline expectations
   - Use factors: achievements, progression, problem_solving
   - Focus on scenarios relevant to the role's challenges

4. Closing (1 cultural question + candidate questions):
   - End with positive cultural discussion related to team structure
   - Use factors: cultural_fit, attitude
   - Consider reporting structure and team size
   - Add final question for candidate's questions (type: 'closing')

Return a JSON array of objects, each with:
- text: The interview question (10-20 words), specifically tailored to the role context
- type: Must use the exact factor IDs specified above
- description: Array of 3-4 key evaluation points that relate to the role requirements

Ensure:
- Questions are highly specific to the provided role and company context
- Evaluate against the actual responsibilities and outcomes
- Consider the team structure and reporting relationships
- Match the company's industry and size context
- Follow the sequence strictly

Return the response as a plain JSON array without any markdown formatting.`
        },
        {
          role: "user",
          content: context
        }
      ],
      temperature: 0.7
    };

    const data = await fetchFromChatGPT(payload);
    const cleanContent = cleanJsonResponse(data.choices[0].message.content);
    const questions = JSON.parse(cleanContent);

    // Validate the sequence and factor types
    const validatedQuestions = questions.map((q, index, array) => {
      const total = array.length;
      let type = q.type;

      // Force the sequence
      if (index === 0 || index === 1) {
        // First two should be cultural
        type = ['cultural_fit', 'attitude'][index % 2];
      } else if (index === total - 2) {
        // Second to last should be cultural
        type = 'cultural_fit';
      } else if (index === total - 1) {
        // Last question is the closing
        type = 'closing';
      } else if (index < total / 2) {
        // First half (after cultural) should be skills
        type = FACTOR_CATEGORIES.technical.factors[index % 3];
      } else {
        // Second half should be performance
        type = FACTOR_CATEGORIES.performance.factors[index % 3];
      }

      return {
        id: `question_${Date.now()}_${Math.random()}`,
        text: q.text,
        type,
        description: q.description
      };
    });

    return validatedQuestions;

  } catch (error) {
    console.error('Error generating interview scheme:', error);
    return [];
  }
}; 