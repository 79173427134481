import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useFormContext } from '../contexts/FormContext';
import { getRole } from '../services/roleStorage';
import { APP_BASENAME } from '../App';

function RoleViewer() {
  const { role } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { 
    updateCompanyData, 
    updateRoleData, 
    updateTimelines, 
    updateResponsibilities, 
    updateOutcomes,
    setProModeEnabled
  } = useFormContext();

  useEffect(() => {
    const fetchRole = async () => {
      try {
        console.log('[RoleViewer] Fetching role:', role);
        
        const response = await getRole(role);
        console.log('[RoleViewer] Response:', response);
        
        if (!response?.data) {
          throw new Error('Invalid response format');
        }
        
        const roleData = response.data;
        
        // Update form context
        updateCompanyData(roleData.companyData || {});
        updateRoleData(roleData.roleData || {});
        updateTimelines(roleData.timelines || {});
        updateResponsibilities(roleData.responsibilities || []);
        updateOutcomes(roleData.outcomes || []);

        // Navigate to the main app after loading data
        navigate(APP_BASENAME);
      } catch (err) {
        console.error('[RoleViewer] Error:', err);
        setError(err.message || 'Failed to load role');
      } finally {
        setLoading(false);
      }
    };

    if (role) {
      fetchRole();
    }
  }, [role, navigate, updateCompanyData, updateRoleData, updateTimelines, updateResponsibilities, updateOutcomes]);

  if (loading) {
    return (
      <div className="min-h-screen bg-gray-50 py-8">
        <div className="max-w-5xl mx-auto">
          <div className="text-center">Loading role data...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 py-8">
        <div className="max-w-5xl mx-auto">
          <div className="text-center text-red-600">Error: {error}</div>
        </div>
      </div>
    );
  }

  return null;
}

export default RoleViewer;