import React from 'react';
import { ChevronUp, ChevronDown, Trash2 } from 'lucide-react';

const QuestionReorderControls = ({ index, totalQuestions, onReorder, onDelete, movingIndex }) => {
  return (
    <div className="flex items-center gap-2">
      <div className="flex flex-col">
        {index !== 0 && (
          <button
            onClick={() => onReorder(-1)}
            disabled={movingIndex !== null}
            className="p-0.5 rounded hover:bg-gray-100 disabled:opacity-50 
              disabled:hover:bg-transparent group-hover:opacity-100 opacity-0 
              transition-opacity"
            title="Move question up"
          >
            <ChevronUp className="h-3.5 w-3.5 text-gray-600" />
          </button>
        )}
        {index !== totalQuestions - 1 && (
          <button
            onClick={() => onReorder(1)}
            disabled={movingIndex !== null}
            className="p-0.5 rounded hover:bg-gray-100 disabled:opacity-50 
              disabled:hover:bg-transparent group-hover:opacity-100 opacity-0 
              transition-opacity"
            title="Move question down"
          >
            <ChevronDown className="h-3.5 w-3.5 text-gray-600" />
          </button>
        )}
      </div>
      <button
        onClick={onDelete}
        className="p-1 rounded hover:bg-red-100 group-hover:opacity-100 opacity-0 
          transition-opacity"
        title="Delete question"
      >
        <Trash2 className="h-4 w-4 text-red-600" />
      </button>
    </div>
  );
};

export default QuestionReorderControls; 