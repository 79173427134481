export const getTypeStyles = (type) => {
  switch (type) {
    case 'warmup':
      return 'bg-emerald-50 text-emerald-600 border-emerald-100';
    case 'background':
      return 'bg-violet-50 text-violet-600 border-violet-100';
    case 'technical':
      return 'bg-green-50 text-green-600 border-green-100';
    case 'behavioral':
      return 'bg-blue-50 text-blue-600 border-blue-100';
    case 'situational':
      return 'bg-purple-50 text-purple-600 border-purple-100';
    case 'cultural':
      return 'bg-amber-50 text-amber-600 border-amber-100';
    case 'closing':
      return 'bg-rose-50 text-rose-600 border-rose-100';
    default:
      return 'bg-gray-50 text-gray-600 border-gray-200';
  }
};

export const getTypeInfo = (type) => {
  switch (type) {
    case 'warmup':
      return {
        type: 'Warm-up',
        max: 20,
        tip: 'Warm-up questions should be conversational and help build rapport.'
      };
    case 'background':
      return {
        type: 'Background',
        max: 25,
        tip: 'Background questions should focus on relevant experience and qualifications.'
      };
    case 'technical':
      return {
        type: 'Technical',
        max: 20,
        tip: 'Technical questions should be direct and focused on specific skills or knowledge.'
      };
    case 'behavioral':
      return {
        type: 'Behavioral',
        max: 30,
        tip: 'Use STAR format: Situation, Task, Action, Result.'
      };
    case 'situational':
      return {
        type: 'Situational',
        max: 25,
        tip: 'Present clear hypothetical scenarios relevant to the role.'
      };
    case 'cultural':
      return {
        type: 'Cultural',
        max: 25,
        tip: 'Focus on work style, values, and team dynamics.'
      };
    case 'closing':
      return {
        type: 'Closing',
        max: 20,
        tip: 'Allow candidates to demonstrate interest and seek clarification.'
      };
    default:
      return {
        type: 'Question',
        max: 30,
        tip: 'Keep questions concise and focused on a single topic.'
      };
  }
};

export const getPlaceholder = (type) => {
  switch (type) {
    case 'warmup':
      return 'What interests you about...';
    case 'background':
      return 'What experience do you have in...';
    case 'technical':
      return 'How would you approach...';
    case 'behavioral':
      return 'Tell me about a time when...';
    case 'situational':
      return 'How would you handle...';
    case 'cultural':
      return 'What type of environment...';
    case 'closing':
      return 'What questions do you have about...';
    default:
      return 'Enter interview question...';
  }
}; 