import { getTypeLabel } from './questionTypeStyles';

export const generateInterviewDOCX = async (questions, formData) => {
  try {
    // Dynamically import docx
    const docx = await import('docx');
    const { Document, Paragraph, TextRun, HeadingLevel, Packer } = docx;

    // Create sanitized filename
    const jobTitle = (formData?.roleData?.jobTitle || 'role').replace(/[^a-z0-9]/gi, '-').toLowerCase();
    const companyName = (formData?.companyData?.name || 'company').replace(/[^a-z0-9]/gi, '-').toLowerCase();
    const fileName = `interview-guide-${companyName}-${jobTitle}.docx`;

    // Create document with safe fallbacks
    const doc = new Document({
      sections: [{
        properties: {},
        children: [
          // Header
          new Paragraph({
            text: `Interview Guide: ${formData?.roleData?.jobTitle || 'Role'}`,
            heading: HeadingLevel.HEADING_1,
            spacing: { after: 200 }
          }),

          // Company Info
          new Paragraph({
            children: [
              new TextRun({
                text: `Company: ${formData?.companyData?.name || 'Company'}`,
                bold: true,
                size: 24
              })
            ],
            spacing: { after: 200 }
          }),
          new Paragraph({
            children: [
              new TextRun({
                text: `Department: ${formData?.roleData?.department || 'Department'}`,
                size: 24
              })
            ],
            spacing: { after: 400 }
          }),

          // Questions
          ...questions.flatMap((question, index) => [
            // Question Header
            new Paragraph({
              children: [
                new TextRun({
                  text: `Question ${index + 1} (${question.category})`,
                  bold: true,
                  size: 24
                })
              ],
              spacing: { before: 400, after: 200 }
            }),
            // Question Text
            new Paragraph({
              text: question.text || '',
              spacing: { after: 200 }
            }),
            // Evaluation Points Header
            new Paragraph({
              children: [
                new TextRun({
                  text: "Key points to evaluate:",
                  italics: true,
                  size: 24
                })
              ],
              spacing: { before: 200, after: 100 }
            }),
            // Evaluation Points with safety checks
            ...(Array.isArray(question.description) 
              ? question.description.map(point => new Paragraph({
                  text: `• ${point || ''}`,
                  indent: { left: 400 },
                  spacing: { after: 100 }
                }))
              : [new Paragraph({
                  text: `• ${question.description || ''}`,
                  indent: { left: 400 },
                  spacing: { after: 100 }
                })]
            )
          ])
        ]
      }]
    });

    // Generate and download the document
    const blob = await Packer.toBlob(doc);
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    URL.revokeObjectURL(url);
  } catch (error) {
    console.error('Error generating DOCX:', error);
    throw error;
  }
}; 